import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { ArrowLeftIcon, QrCodeIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import { generateQRCode } from 'api/connections';
import { authStore } from 'stores/AuthStore';
import { observer } from 'mobx-react';
import FooterNav from 'components/FooterNav';
import { getConnections } from 'api/connections';

interface BusinessCardPageProps {
  className?: string;
}

const BusinessCardPage: React.FC<BusinessCardPageProps> = () => {
  const [qrCode, setQrCode] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [initialConnectionCount, setInitialConnectionCount] = useState<number | null>(null);
  const navigate = useNavigate();
  const userData = authStore.currentUser;

  useEffect(() => {
    // Set a timer to navigate after 2 minutes
    const timer = setTimeout(() => {
      navigate('/'); // Replace with the desired route
    }, 120000); // 120,000 ms = 2 minutes

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  const generateQRCodeFunction = async () => {
    setIsGenerating(true);
    try {
      const response = await generateQRCode(userData?.id as string);
      setQrCode(response.qrData);
    } catch (error) {
      console.error('Error generating QR code:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const checkForNewConnections = async () => {
    try {
      const connections = await getConnections();
      
      // If this is our first check, just store the initial count
      if (initialConnectionCount === null) {
        setInitialConnectionCount(connections.length);
        return;
      }

      // If we have more connections than we started with, navigate to connections page
      if (connections.length > initialConnectionCount) {
        navigate('/connections', { replace: true });
      }
    } catch (error) {
      console.error('Error checking for new connections:', error);
    }
  };

  useEffect(() => {
    generateQRCodeFunction();
    
    // Start polling for new connections
    const pollInterval = setInterval(checkForNewConnections, 3000); // Check every 3 seconds

    return () => {
      clearInterval(pollInterval); // Cleanup on unmount
    };
  }, [initialConnectionCount]);

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-lg mx-auto px-4">
        {/* Back Navigation */}
        <button
          onClick={() => navigate(-1)}
          className="group mb-6 inline-flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900 transition-colors"
        >
          <ArrowLeftIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-600 transition-colors" />
          <span>Back</span>
        </button>

        {/* Business Card */}
        <div className="bg-white rounded-2xl shadow-lg border border-gray-100 overflow-hidden">
          {/* Header */}
          <div
            className="relative h-48 bg-gradient-to-r from-indigo-500 to-blue-500"
            style={{
              backgroundImage: userData?.profile.bgImg
                ? `url(${userData.profile.bgImg})`
                : undefined,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="absolute -bottom-16 left-6">
              <Avatar
                imgUrl={userData?.profile.avatar}
                userName={userData?.profile.fullName}
                sizeClass="w-32 h-32"
              />
            </div>
          </div>

          {/* Content */}
          <div className="pt-20 p-6">
            <h1 className="text-2xl font-semibold text-gray-900">{userData?.profile.fullName}</h1>
            <p className="mt-1 text-sm text-gray-600">
              {userData?.profile.role} at {userData?.profile.companyName}
            </p>

            {/* Social Links */}
            <div className="mt-4 flex gap-4">
              {userData?.profile.linkedinUrl && (
                <a
                  href={userData.profile.linkedinUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-blue-600 transition"
                >
                  <i className="fab fa-linkedin text-xl"></i>
                </a>
              )}
              {userData?.email && (
                <a
                  href={`mailto:${userData.email}`}
                  className="text-gray-600 hover:text-red-600 transition"
                >
                  <i className="fas fa-envelope text-xl"></i>
                </a>
              )}
            </div>

            {/* Industry Badge */}
            <div className="mt-4">
              <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                {userData?.profile.industry}
              </span>
            </div>

            {/* QR Code Section */}
            <div className="mt-8 flex flex-col items-center">
              <div className="relative flex items-center justify-center w-60 h-60">
                <div className="absolute w-64 h-64 rounded-full bg-indigo-200 animate-ping"></div>
                <div className="absolute w-72 h-72 rounded-full bg-indigo-300 opacity-50 animate-ping"></div>
                <div className="relative p-4 bg-white rounded-lg shadow-md">
                  {qrCode ? (
                    <QRCodeSVG
                      value={qrCode}
                      size={200}
                      level="H"
                      includeMargin
                    />
                  ) : (
                    <div className="w-48 h-48 flex items-center justify-center animate-pulse">
                      <QrCodeIcon className="w-16 h-16 text-gray-300" />
                    </div>
                  )}
                </div>
              </div>

              <button
                onClick={generateQRCodeFunction}
                disabled={isGenerating}
                className="mt-6 px-6 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isGenerating ? 'Generating...' : 'Refresh QR Code'}
              </button>

              <p className="mt-3 text-xs text-gray-500">
                Scan this QR code to connect with {userData?.profile.fullName}
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterNav />
    </div>
  );
};

export default observer(BusinessCardPage);
