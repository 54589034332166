import { AxiosResponse } from 'axios'
import { UpdateProfileDto, User, UserProfile } from 'interface/user.interface'
import { OnboardingData } from 'stores/OnboardingStore'
import axiosInstance from 'utils/setAuthorizationHeader'

export async function sendOnboardingData(data: OnboardingData) {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/profile/onboarding`, data, { withCredentials: true })
}

export async function updateUserProfileOnBoardingDone(user: Partial<User>) {
    return axiosInstance.patch(`${process.env.REACT_APP_API_URL}/profile/${user.id}`, user, { withCredentials: true })
}

export async function updateUserProfile(updatedProfile: UpdateProfileDto) {
    return axiosInstance.patch(`${process.env.REACT_APP_API_URL}/profile/account/${updatedProfile.id}`, updatedProfile, { withCredentials: true })
}

export async function updateUserAvatar(file: FormData): Promise<AxiosResponse<string>> {
    return axiosInstance.patch(`${process.env.REACT_APP_API_URL}/profile/avatar`, file, { withCredentials: true })
}

export async function updateUserBackgroundImage(file: FormData): Promise<AxiosResponse<string>> {
    return axiosInstance.patch(`${process.env.REACT_APP_API_URL}/profile/background-image`, file, { withCredentials: true });
}
    
// get user profile by ids 
export async function getUsersProfileById(ids: string[]): Promise<UserProfile> {
    return await axiosInstance.post(`${process.env.REACT_APP_API_URL}/profile/accounts`, { userIds: ids }, { withCredentials: true })
}

export async function getIntroStatus(introName: string): Promise<boolean> {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/profile/intro/status?introName=${introName}`, { withCredentials: true });
    return response.data.completed;
}

export async function markIntroAsCompleted(introName: string): Promise<void> {
    axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/profile/intro/complete`,
        { introName: introName },
        { withCredentials: true }
    );
}

export async function trackProfileView(profileId: string): Promise<void> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/profile/${profileId}/view`, {}, { withCredentials: true });
}

export async function getProfileViews(): Promise<{ views: any[]; totalCount: number }> {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/profile/me/views`, { withCredentials: true });
    return response.data;
}

export const getProfileViewStatistics = async (days: number = 30) => {
  const { data } = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/profile/me/views/statistics?days=${days}`);
  return data;
};