import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Html5Qrcode } from "html5-qrcode";
import React, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface QRScannerPageProps {
  isOpen: boolean;
  onClose: () => void;
  onScan?: (qrData: string) => void;
}

export const QRScannerPage: React.FC<QRScannerPageProps> = ({
  isOpen,
  onClose,
  onScan,
}) => {
  const [html5QrCode, setHtml5QrCode] = useState<Html5Qrcode | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  useEffect(() => {
    if (isOpen) {
      checkCameraPermission();
    }

    return () => {
      stopScanner();
    };
  }, [isOpen]);

  useEffect(() => {
    if (hasPermission && isOpen) {
      initializeScanner();
    }
  }, [hasPermission, isOpen]);

  const checkCameraPermission = async () => {
    try {
      if (navigator.permissions && navigator.permissions.query) {
        const result = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });
        if (result.state === "granted") {
          setHasPermission(true);
        } else if (result.state === "prompt") {
          await requestCameraPermission();
        } else {
          setHasPermission(false);
          setError("Camera permission denied. Please enable camera access.");
        }
      } else {
        await requestCameraPermission();
      }
    } catch (err) {
      console.error("Permission check failed:", err);
      await requestCameraPermission();
    }
  };

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop()); // Stop stream after permission check
      setHasPermission(true);
    } catch (err) {
      setHasPermission(false);
      setError("Camera access denied. Please allow access to use the scanner.");
      console.error("Permission request failed:", err);
    }
  };

  const initializeScanner = async () => {
    if (!isOpen || !hasPermission) return;
  
    const qrReaderElement = document.getElementById("qr-reader");
    if (!qrReaderElement) {
      setError("QR reader element not found.");
      return;
    }
    
    const scanner = new Html5Qrcode("qr-reader");
    setHtml5QrCode(scanner);
  
    try {
      await scanner.start(
        { facingMode: "environment" },
        {
          fps: 10,
          // Prevent multiple scans by adding a small delay
          disableFlip: true, // Disable flip code detection
          aspectRatio: 1.0 // Use square aspect ratio for better scanning
        },
        async (decodedText) => {
          // Stop scanner immediately to prevent multiple scans
          await scanner.stop();
          
          if (onScan) {
            onScan(decodedText);
          }
          stopScanner();
          onClose();
        },
        (errorMessage) => {
          console.error("QR Scanner Error:", errorMessage);
        }
      );
    } catch (err) {
      setError("Failed to initialize QR scanner.");
      console.error("Scanner initialization failed:", err);
    }
  };
  

  const stopScanner = async () => {
    if (html5QrCode) {
      try {
        await html5QrCode.stop();
        await html5QrCode.clear();
        setHtml5QrCode(null);
      } catch (err) {
        console.error("Failed to stop QR scanner:", err);
      }
    }
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="absolute right-4 top-4">
                <button
                  type="button"
                  className="rounded-lg p-1 text-gray-400 hover:text-gray-500"
                  onClick={onClose}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Scan QR Code
              </Dialog.Title>

              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Use your camera to scan the QR code of the person you want to
                  connect with. Ensure the code is well-lit and centered.
                </p>
              </div>

              <div className="mt-4 overflow-hidden">
                {!hasPermission ? (
                  <div className="rounded-lg bg-red-50 p-4">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                ) : (
                  <div
                    id="qr-reader"
                    className="w-full h-64 bg-gray-200 flex items-center justify-center overflow-hidden"
                  >
                    <p className="text-sm text-gray-500">
                      Initializing scanner...
                    </p>
                  </div>
                )}
              </div>

              <div className="mt-6 flex justify-center">
                <ButtonPrimary
                  onClick={onClose}
                  className="px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition"
                >
                  Close Scanner
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default QRScannerPage;
