import { ArrowTopRightOnSquareIcon, HandThumbDownIcon, HandThumbUpIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { getMatches, submitUserMatchInteraction } from 'api/matches';
import aiThinkingAnimation from 'images/lottie/aI-thinking.json';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { Spinner } from 'shared/Loader/Spinner';

export interface MatchCandidate {
  id: string;
  candidateId: string;
  candidateName: string;
  candidateData: {
    goals: string[];
    industry: string;
    role: string;
    location: string;
    country: string;
    bio: string | null;
    avatarUrl: string;
    companyName: string | null;
  };
  matchScore: number;
  matchDetails: {
    goalsScore: number;
    industryScore: number;
    roleScore: number;
    locationScore: number;
  };
  matchReasons: string[];
  aiDescription: string;
  interest?: 'interested' | 'not_interested' | 'pending' | null;
  profileId?: string;
}

interface MatchCardProps {
  match: MatchCandidate;
  onAction: (action: 'L' | 'D' | 'S') => Promise<void>;
  isLast?: boolean;
}

export const MatchCard: React.FC<MatchCardProps> = ({ match, onAction, isLast }) => {
  const [showAIDescription, setShowAIDescription] = useState(false);
  const [loadingAction, setLoadingAction] = useState<'L' | 'D' | 'S' | null>(null);
  const navigate = useNavigate();

  const handleAction = async (action: 'L' | 'D' | 'S') => {
    setLoadingAction(action);
    try {
      await onAction(action);
    } finally {
      setLoadingAction(null);
    }
  };

  const isInterested = match.interest === 'interested';
  const isNotInterested = match.interest === 'not_interested';

  const handleProfileClick = () => {
    const id = match.profileId || match.candidateId;
    navigate(`/user/${id}/profile`);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 relative overflow-hidden">
      {/* Match Score Badge */}
      <div className="absolute top-3 right-3 sm:top-4 sm:right-4 bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs sm:text-sm font-medium">
        {match.matchScore}% Match
      </div>

      <div className="flex flex-col sm:flex-row sm:items-start sm:space-x-4">
        {/* Avatar */}
        <div className="flex-shrink-0 mb-4 sm:mb-0">
          <img
            src={match.candidateData.avatarUrl || '/default-avatar.png'}
            alt={match.candidateName}
            className="w-20 h-20 sm:w-16 sm:h-16 rounded-full object-cover mx-auto sm:mx-0"
          />
        </div>

        {/* Content */}
        <div className="flex-1 text-center sm:text-left">
          <div className="flex items-center justify-center sm:justify-start space-x-2">
            <h3 className="text-xl font-semibold text-gray-900">{match.candidateName}</h3>
            <button
              onClick={() => setShowAIDescription(!showAIDescription)}
              className="inline-flex items-center justify-center p-1.5 rounded-full bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors"
              title="AI Match Insights"
              aria-label="View AI match insights"
            >
              <SparklesIcon className="w-4 h-4" />
              <span className="sr-only">View AI match insights</span>
            </button>
            <button
              onClick={handleProfileClick}
              className="inline-flex items-center justify-center p-1.5 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors"
              title="View Profile"
              aria-label="View full profile"
            >
              <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              <span className="sr-only">View full profile</span>
            </button>
          </div>
          <p className="text-gray-600">
            {match.candidateData.role} • {match.candidateData.industry}
          </p>

          {/* Goals */}
          <div className="mt-2 flex flex-wrap gap-2 justify-center sm:justify-start">
            {match.candidateData.goals?.map((goal, index) => (
              <span
                key={index}
                className="bg-blue-50 text-blue-700 px-2 py-1 rounded-full text-xs sm:text-sm"
              >
                {goal}
              </span>
            ))}
          </div>

          {/* Bio */}
          <div className="mt-3 text-gray-700 text-sm sm:text-base">
            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              className="content-css"
              anchorClass="text-blue-600 hover:text-blue-700 ml-1"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {match.candidateData.bio || 'No bio provided'}
            </ShowMoreText>
          </div>

          {/* Match Reasons */}
          <div className="mt-4">
            <h4 className="font-medium text-gray-900 mb-2">Why you match:</h4>
            <ul className="space-y-1">
              {match.matchReasons.map((reason, index) => (
                <li key={index} className="text-gray-600 text-sm flex items-start space-x-2">
                  <span className="text-green-500 flex-shrink-0">•</span>
                  <span>{reason}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* AI Description */}
          {showAIDescription && (
            <div className="mt-4 p-4 bg-purple-50 rounded-lg border border-purple-100">
              <div className="flex items-start space-x-2">
                <SparklesIcon className="w-5 h-5 text-purple-600 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-medium text-purple-900 mb-1">AI Match Insights</h4>
                  <p className="text-purple-800 text-sm">{match.aiDescription}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="mt-6 flex flex-row gap-2 sm:gap-4 justify-center">
        <button
          onClick={() => handleAction('L')}
          disabled={isInterested || loadingAction !== null}
          className={`flex items-center justify-center space-x-2 px-4 py-2 rounded-full transition-colors w-1/2 sm:w-auto
              ${isInterested
              ? 'bg-green-50 border border-green-200 text-green-600 cursor-not-allowed'
              : 'bg-blue-600 text-white hover:bg-blue-700'}`}
        >
          {loadingAction === 'L' ? (
            <Spinner size="sm" />
          ) : (
            <HandThumbUpIcon className="w-5 h-5" />
          )}
          <span className="text-sm sm:text-base">
            {isInterested ? 'Interested' : 'Interested'}
          </span>
        </button>
        <button
          onClick={() => handleAction('D')}
          disabled={isNotInterested || loadingAction !== null}
          className={`flex items-center justify-center space-x-2 px-4 py-2 rounded-full border transition-colors w-1/2 sm:w-auto
              ${isNotInterested
              ? 'bg-red-50 border-red-200 text-red-600 cursor-not-allowed'
              : 'border-gray-300 hover:bg-gray-50'}`}
        >
          {loadingAction === 'D' ? (
            <Spinner size="sm" />
          ) : (
            <HandThumbDownIcon className="w-5 h-5 text-gray-500" />
          )}
          <span className="text-sm sm:text-base">
            {isNotInterested ? 'Not Interested' : 'Not Interested'}
          </span>
        </button>
      </div>
    </div>
  );
};

const MatchesPage: React.FC = () => {
  const [matches, setMatches] = useState<MatchCandidate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aiThinkingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const fetchMatches = async () => {
    try {
      const response = await getMatches();
      // Sort matches: null interest first, then interested/not_interested
      const sortedMatches = response.data.sort((a: MatchCandidate, b: MatchCandidate) => {
        if (!a.interest && b.interest) return -1;
        if (a.interest && !b.interest) return 1;
        return 0;
      });
      setMatches(sortedMatches);
    } catch (err) {
      setError('Failed to load matches. Please try again later.');
      console.error('Error fetching matches:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  const handleMatchAction = async (matchId: string, action: 'L' | 'D' | 'S') => {
    try {
      if (action === 'S') {
        // Handle skip action locally
        const updatedMatches = matches.filter(m => m.id !== matchId);
        setMatches(updatedMatches);
        return;
      }

      // Optimistically update UI
      const updatedMatches = matches.map(match => {
        if (match.id === matchId) {
          return {
            ...match,
            interest: action === 'L' ? 'interested' as const : 'not_interested' as const
          };
        }
        return match;
      });
      setMatches(updatedMatches);

      // Make API call
      await submitUserMatchInteraction(matchId, action);

      toast.success(
        action === 'L'
          ? "Great! We'll notify them of your interest."
          : "Preference saved. We'll refine your matches."
      );
    } catch (err: any) {
      // Revert optimistic update on error
      const originalMatch = matches.find(m => m.id === matchId);
      if (originalMatch) {
        const revertedMatches = matches.map(match =>
          match.id === matchId ? originalMatch : match
        );
        setMatches(revertedMatches);
      }

      const errorMessage = err.response?.data?.message || 'Failed to save your preference. Please try again.';
      toast.error(errorMessage);
      console.error('Error submitting match interaction:', err);
      throw err; // Re-throw to handle in the UI
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="w-64 h-64 mb-4">
          <Lottie options={defaultOptions} />
        </div>
        <p className="text-gray-600 text-lg">Finding your matches...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4">
        <p className="text-red-600 mb-4">{error}</p>
        <button
          onClick={fetchMatches}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (matches?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4 text-center">
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No New Matches</h2>
        <p className="text-gray-600 mb-4">
          We're working on finding your next perfect match. Check back later!
        </p>
        <button
          onClick={fetchMatches}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Refresh
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Your Matches</h1>
          <p className="mt-2 text-gray-600">
            Discover professionals who align with your goals and interests
          </p>
        </header>

        <div className="space-y-6">
          {matches?.sort((a, b) => {
            // Sort pending matches first, others after
            if (a.interest === 'pending' && b.interest !== 'pending') return -1;
            if (a.interest !== 'pending' && b.interest === 'pending') return 1;
            return 0;
          }).map((match, index) => (
            <MatchCard
              key={match.candidateId}
              match={match}
              onAction={(action) => handleMatchAction(match.id, action)}
              isLast={index === matches.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(MatchesPage); 