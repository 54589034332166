import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, XMarkIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { createBulletin } from "api/bulletins";
import { BulletinResponse } from "interface/communities";
import React, { FC, Fragment, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export interface DigitalBulletinHeadingProps {
  communityId: string;
  onBulletinCreated?: (bulletin: BulletinResponse) => void;
}

const DigitalBulletinHeading: FC<DigitalBulletinHeadingProps> = ({
  communityId,
  onBulletinCreated
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ title: "", content: "", communityId: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setFormData({ title: "", content: "", communityId: "" });
    setIsModalOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.title.trim() || !formData.content.trim()) {
      toast.error("Both title and description are required.", { theme: "light" });
      return;
    }

    try {
      setIsSubmitting(true);
      formData.communityId = communityId;
      const response = await createBulletin(formData);
      toast.success("Announcement posted successfully!", { theme: "light" });
      if (onBulletinCreated) {
        onBulletinCreated(response.data);
      }
      closeModal();
    } catch (error) {
      console.error("Error posting announcement:", error);
      toast.error("Failed to post the announcement. Please try again.", { theme: "light" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewAll = () => {
    navigate(`/community/${communityId}/board`);
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <div>
          <h2 className="text-base font-semibold text-gray-900">Community Board</h2>
          <p className="mt-1 text-sm text-gray-500">
            Share updates and announcements with your community
          </p>
        </div>
        
        <div className="flex items-center gap-3">
          <button
            onClick={handleViewAll}
            className="inline-flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-gray-600 hover:text-gray-900 transition-colors"
          >
            <span className="hidden sm:inline">View All</span>
            <span className="sm:hidden">All Posts</span>
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </button>
          
          <button
            onClick={openModal}
            className="inline-flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 hover:text-gray-900 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <PlusIcon className="w-4 h-4" />
            <span className="hidden sm:inline">New</span>
            <span className="sm:hidden">Post</span>
          </button>
        </div>
      </div>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/20 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 shadow-xl transition-all">
                  <div className="absolute right-4 top-4">
                    <button
                      type="button"
                      className="rounded-lg p-1 text-gray-400 hover:text-gray-500"
                      onClick={closeModal}
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>

                  <div className="mb-6">
                    <Dialog.Title className="text-base font-semibold text-gray-900">
                      New Announcement
                    </Dialog.Title>
                    <Dialog.Description className="mt-1 text-sm text-gray-500">
                      Share important updates with your community members
                    </Dialog.Description>
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        Title
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-lg border-gray-200 text-sm shadow-sm focus:border-gray-500 focus:ring-0"
                        placeholder="Enter a clear, descriptive title"
                      />
                    </div>

                    <div>
                      <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                        Message
                      </label>
                      <textarea
                        id="content"
                        name="content"
                        rows={4}
                        value={formData.content}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-lg border-gray-200 text-sm shadow-sm focus:border-gray-500 focus:ring-0"
                        placeholder="What would you like to announce?"
                      />
                    </div>

                    <div className="mt-6 flex justify-end gap-3">
                      <button
                        type="button"
                        onClick={closeModal}
                        className="rounded-lg px-3 py-1.5 text-sm font-medium text-gray-600 hover:text-gray-800"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="inline-flex items-center justify-center rounded-lg bg-gray-900 px-3 py-1.5 text-sm font-medium text-white hover:bg-gray-800 disabled:opacity-50"
                      >
                        {isSubmitting ? (
                          <>
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                            </svg>
                            Posting...
                          </>
                        ) : (
                          'Post Announcement'
                        )}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DigitalBulletinHeading;
