import React, { useState } from 'react';
import { Game, GameStatus, GuessWhoResult } from '../../../interface/games';
import * as gamesApi from '../../../api/games';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';

interface GuessWhoGameProps {
  game: Game;
  onUpdate: () => void;
}

const GuessWhoGame: React.FC<GuessWhoGameProps> = ({ game, onUpdate }) => {
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const currentUser = authStore.currentUser;

  const members = game.gameData.members || [];
  const hints = game.gameData.hints || [];
  
  const isGameOver = game.status === GameStatus.CLOSED || !!game.participations.find(p => currentUser?.id === p.user.id && p.hasCompleted);
  const myParticipation = game.participations.find(p => currentUser?.id === p.user.id);
  console.log(isGameOver);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedUserId) {
      setError('Please select a community member');
      return;
    }

    try {
      await gamesApi.submitMove(game.id, { guessedUserId: selectedUserId });
      setSelectedUserId('');
      setError(null);
      onUpdate();
    } catch (err) {
      setError('Failed to submit guess');
      console.error(err);
    }
  };

  const renderHints = () => (
    <div className="mb-6 space-y-4">
      <h3 className="text-lg font-semibold">Profile Hints:</h3>
      <div className="bg-blue-50 p-4 rounded-lg space-y-2">
        {hints.map((hint: string, index: number) => (
          <div key={index} className="text-gray-700">
            • {hint}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      {renderHints()}

      {!isGameOver && game.status === GameStatus.ACTIVE && (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select a Community Member
            </label>
            <select
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">Choose a member...</option>
              {members?.map((member: any) => (
                <option key={member.id} value={member.id}>
                  {member.fullName}
                </option>
              ))}
            </select>
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
          >
            Submit Guess
          </button>
        </form>
      )}

      {isGameOver && (
        <div className="text-center p-4 bg-gray-50 rounded">
          <h3 className="text-xl font-bold mb-2">Game Over!</h3>
          <p className="text-gray-600">
            {myParticipation?.hasCompleted && myParticipation.score > 0
              ? 'Congratulations! You found the right person!'
              : 'Better luck next time!'}
          </p>
          {myParticipation?.hasCompleted && myParticipation.score === 0 && (
            <a
              href={`/user/${game.gameData.targetUserId}`}
              className="mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
            >
              Reveal Who It Was
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(GuessWhoGame); 