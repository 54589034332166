import { AxiosResponse } from 'axios';
import { PaginationResult } from 'interface/pagination';
import axiosInstance from 'utils/setAuthorizationHeader';

export enum MatchInterestStatus {
  INTERESTED = 'interested',
  NOT_INTERESTED = 'not_interested',
  PENDING = 'pending'
}

export async function getMatches(): Promise<AxiosResponse<any>> {
   const url = `${process.env.REACT_APP_API_URL}/matches/discover`
   return axiosInstance.get(url, { withCredentials: true })
}

export async function getPotentialMatches(country: string, matchLevel: 'High' | 'Medium' | 'Low' = 'High'): Promise<AxiosResponse<any>> {
   const url = `${process.env.REACT_APP_API_URL}/matches/potential?country=${country}&level=${matchLevel}`
   return axiosInstance.get(url, { withCredentials: true })
}

export async function submitUserMatchInteraction(
  matchId: string, 
  interactionType: 'L' | 'D'
): Promise<AxiosResponse<any>> {
   const url = `${process.env.REACT_APP_API_URL}/matches/${matchId}/interest`;

   const interest = interactionType === 'L' ? 
     MatchInterestStatus.INTERESTED : 
     MatchInterestStatus.NOT_INTERESTED;

   return axiosInstance.post(url, {
      interest,
   }, { withCredentials: true });
}


export const sendConnectionRequest = async (receiverId: string) => {
   try {
      if (!receiverId) throw new Error('No receiverId provided');
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/connection/request`, { receiverId }, { withCredentials: true });
      console.log('Connection request sent:', response.data);
   } catch (error: any) {
      console.error('Error sending connection request:', error?.response?.data || error.message);
   }
};

export const sendCommunityMatchSearchRequest = async (searchCriteria: string) => {
   try {
      if (!searchCriteria?.trim()) throw new Error('No search criteria provided');
      
      const response = await axiosInstance.post(
         `${process.env.REACT_APP_API_URL}/matches/ai/community/search`, 
         { searchCriteria }, 
         { withCredentials: true }
      );
      
      return response.data;
   } catch (error: any) {
      console.error('Error in community match search:', error?.response?.data || error.message);
      throw error; // Re-throw the error for proper error handling
   }
};

export const sendEnterpriseMatchSearchRequest = async (searchCriteria: string) => {
   try {
      if (!searchCriteria) throw new Error('No search criteria provided');
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/matches/ai/enterprise/search`, { searchCriteria }, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};

export const sendMatchSearchRequest = async (searchCriteria: string) => {
   try {
      if (!searchCriteria) throw new Error('No search criteria provided');
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/matches/ai/opportunities/search`, { searchCriteria }, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};

export const sendQuickMatchSearchRequest = async () => {
   try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/matches/ai/opportunities/quick-search`, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};

export const sendEnterpriseQuickMatchSearchRequest = async () => {
   try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/matches/ai/enterprise/quick-search`, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};


export const sendCommunityQuickMatchSearchRequest = async (communityId: string) => {
   try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/matches/ai/community/quick-search/${communityId}`, { withCredentials: true });
      console.log('Community match search request sent:', response.data);
      return response.data;
   } catch (error: any) {
      console.error('Error sending community match search request:', error?.response?.data || error.message);
   }
};
