import { ProfessionalProfileDto } from "api/professionalLocals";
import { TripDetails, TripDetailsResponse } from "interface/trip.interface";
import moment from "moment-timezone";
import Heading from "components/Heading/Heading";
import NoResultCard from "components/NoResult/GenericNoResultCard";
import SectionSliderDiscoverLocals from "components/SectionSliderDiscoverLocals/SectionSliderDiscoverLocals";
import SectionSliderMatchingBusinessTravelers from "components/SectionSliderMatchingBusinessTravelers/SectionSliderMatchingBusinessTravelers";

interface ExploreSectionProps {
  professionalLocals: ProfessionalProfileDto[];
  arrivalsNextMonth: TripDetailsResponse[];
  isLoading: boolean;
}

export const ExploreSection = ({ professionalLocals, arrivalsNextMonth, isLoading }: ExploreSectionProps) => {
  const startOfNextPeriod = moment().startOf('day').format('ll');
  const endOfNextPeriod = moment().add(31, 'days').endOf('day').format('ll');

  return (
    <>
      <Heading 
        className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50" 
        desc={""} 
        viewAll={{ label: "View all", href: "/professional/locals/1" }}
      >
        Meet Nearby Locals
      </Heading>
      
      <SectionSliderDiscoverLocals
        likeMindedLocals={professionalLocals}
        uniqueClassName="LocalMatches"
        isLoading={isLoading}
      />

      <Heading
        className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50 mt-10"
        desc={`${startOfNextPeriod} - ${endOfNextPeriod}`}
        viewAll={{ label: "View all", href: "/professional/arrivals" }}
      >
        Arrivals Next Month
      </Heading>

      {arrivalsNextMonth.length ? (
        <SectionSliderMatchingBusinessTravelers
          userMatches={arrivalsNextMonth}
          uniqueClassName="ArrivalsNextMonth"
          isLoading={isLoading}
        />
      ) : (
        <NoResultCard displayName="No matching for business travelers... yet." />
      )}
    </>
  );
}; 