import { HOBBIES_CATEGORIES } from "data/taxonomies";
import { InterestsType } from "data/types";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { goals, onboardingStore } from "stores/OnboardingStore";

export interface WidgetTagsProps {
  className?: string;
  tags?: InterestsType[];
}

const InterestsWidgetTags: FC<WidgetTagsProps> = observer(({ className = "", tags = HOBBIES_CATEGORIES }) => {
  const [activeGoals, setActiveGoals] = useState(goals);

  useEffect(() => {
    setActiveGoals(goals);
  }, []);

  const toggleGoal = (goalName: string) => {
    onboardingStore.setGoals(goalName);
  };

  return (
    <div className={`nc-WidgetTags w-full max-w-3xl mx-auto px-4 sm:px-6 ${className}`}>
      <div className="bg-white dark:bg-neutral-900 rounded-2xl shadow-lg p-5 sm:p-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-4">
          {activeGoals.map((goal) => (
            <button
              key={goal.name}
              onClick={() => toggleGoal(goal.name)}
              className={`
                group relative flex flex-col items-center justify-center
                aspect-square p-3 sm:p-4
                rounded-xl transition-all duration-200
                hover:scale-105 hover:shadow-md
                ${
                  onboardingStore.goals.includes(goal.name)
                    ? 'bg-primary-50 dark:bg-primary-900 border-2 border-primary-500 shadow-md'
                    : 'bg-neutral-50 dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700'
                }
              `}
            >
              <span className="text-2xl sm:text-3xl mb-2 transition-transform group-hover:scale-110">
                {goal.icon}
              </span>
              <span className="text-center text-xs sm:text-sm font-medium text-neutral-700 dark:text-neutral-200">
                {goal.name}
              </span>
              {onboardingStore.goals.includes(goal.name) && (
                <span className="absolute top-2 right-2 text-primary-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
});

export default InterestsWidgetTags;
