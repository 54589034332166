import { Dialog, Transition } from "@headlessui/react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import LocationAutocomplete from "components/Common/LocationAutocomplete";
import Label from "components/Label/Label";
import { observer } from "mobx-react";
import { FC, Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";
import { onboardingStore } from "stores/OnboardingStore";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { authStore } from "stores/AuthStore";
import { Languages } from "data/languages";
import { getMetadata } from "api/metadata";
import { Metadata } from "./OnBoardingStep2";
import Autocomplete from "components/AutoComplete/AutoComplete";
export interface OnBoardingStep1Props { }

const OnBoardingStep1: FC<OnBoardingStep1Props> = observer(() => {

  const { currentUser } = authStore

  // Extract the address from the store
  const currentAddress = onboardingStore.location.details?.formatted_address || '';
  const [errors, setErrors] = useState({
    fullName: false,
    location: false,
    jobTitle: false,
    industry: false,
  });


  useEffect(() => {
    onboardingStore.setFullName(currentUser?.profile.fullName || '')
  }, [currentUser?.profile.fullName])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error);
  }, [])

  function error(err: { code: any; message: any; }) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  async function success(pos: GeolocationPosition): Promise<void> {
    const crd = pos.coords;

    if (!google || !google.maps || !google.maps.Geocoder) {
      console.error("Google Maps Geocoder is not available.");
      return;
    }

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      { location: { lat: crd.latitude, lng: crd.longitude } },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {
          onboardingStore.setLocation(results[0]);
        } else {
          console.error("Geocoding failed:", status);
        }
      }
    );
  }

  const handleValidation = () => {
    const newErrors = {
      fullName: !onboardingStore.fullName,
      location: !onboardingStore.locationObject.details,
      jobTitle: !onboardingStore.role,
      industry: !onboardingStore.industry,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error); // Return true if no errors
  };

  const handleContinue = () => {
    if (handleValidation()) {
      onboardingStore.setOnboardingNextStep();
    } else {
      toast.error("Please complete all required fields.", {theme: 'light'});
    }
  };

  const handleLocationChange = (locationDetails: google.maps.GeocoderResult) => {
    onboardingStore.setLocation(locationDetails);
  };

  const handleFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    onboardingStore.setFullName(e.target.value)
  }

  const [metadata, setMetdata] = useState<Metadata>({
    industries: [],
    roles: []
  })

  useEffect(() => {
    getMetadata().then(res => {
      const { data } = res
      setMetdata(data)
    })

    // Check if 'industry' exists in localStorage and set it in onboardingStore
    let enterpriseParams: any = localStorage.getItem('enterpriseParams');

    if (enterpriseParams) {
      enterpriseParams = JSON.parse(enterpriseParams);
    }

    if (enterpriseParams?.industry) {
      onboardingStore.setIndustry(enterpriseParams?.industry);
    }

    if (enterpriseParams?.company_name) {
      onboardingStore.setCompanyName(enterpriseParams?.company_name);
    }
  }, [])

  const handleRoleChanges = (role: string) => {
    onboardingStore.setRole(role)
  }

  const handleIndustryChanges = (industry: string) => {
    onboardingStore.setIndustry(industry)
  }

  const handleCompanyNameChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    onboardingStore.setCompanyName(event.target.value)
  }

  return (
    <CommonLayout
      index="1"
      onContinue={handleContinue}
    >
      <>
        <h2 className="text-2xl font-semibold">👤 Basic Profile Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Display Name" className="space-y-4">
            <Input
              name="displayName"
              placeholder="Enter Display Name"
              onChange={handleFullName}
              value={onboardingStore.fullName}
              className={errors.fullName ? "border-red-500" : ""}
            />
            {errors.fullName && <p className="text-red-500 text-sm">Display Name is required.</p>}
          </FormItem>
          <FormItem label="City" className="space-y-4">
            <LocationAutocomplete
              placeHolder="Select your city"
              address={currentAddress}
              onLocationSelect={handleLocationChange}
            />
            {errors.location && <p className="text-red-500 text-sm">City is required.</p>}
          </FormItem>
          <FormItem label="Job Title">
            <Autocomplete
              defaultValue={onboardingStore.role}
              options={metadata.roles.map(r => r.value)}
              placeholder="Select your job title"
              onChange={handleRoleChanges}
              inputClass={errors.jobTitle ? "border-red-500" : ""}
            />
            {errors.jobTitle && <p className="text-red-500 text-sm">Job Title is required.</p>}
          </FormItem>
          <FormItem
            label="Industry Type"
          >
            <Autocomplete
              defaultValue={onboardingStore.industry}
              options={metadata.industries.map(i => i.value)}
              placeholder="Select the industry type"
              onChange={handleIndustryChanges}
              inputClass={errors.industry ? "border-red-500" : ""}
            />
            {errors.industry && <p className="text-red-500 text-sm">Industry Type is required.</p>}
          </FormItem>
          <FormItem label="Company Name">
            <Input
              onChange={handleCompanyNameChanges}
              value={onboardingStore.companyName || ''}
              placeholder="Enter your company name" />
          </FormItem>

        </div>
      </>
    </CommonLayout>
  );
})

export default OnBoardingStep1;
