import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { sendOnboardingData } from 'api/profile';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { onboardingStore } from 'stores/OnboardingStore';
import CommonLayout from "./CommonLayout";
import { reaction } from 'mobx';
import { authStore } from 'stores/AuthStore';
export const interests: { [key: string]: { emoji: string; topics: string[] } } = {
    Business: {
        emoji: '💼',
        topics: [
            'entrepreneurship', 'marketing', 'sales', 'consulting',
            'e-commerce', 'retail', 'real estate', 'business strategy',
            'startups', 'leadership', 'management', 'human resources',
            'operations', 'supply chain', 'business development',
            'hospitality', 'aviation', 'franchising', 'corporate social responsibility'
        ]
    },
    'Investing & Finance': {
        emoji: '💰',
        topics: [
            'stock market', 'investing', 'personal finance', 'financial planning',
            'wealth management', 'cryptocurrency', 'blockchain', 'venture capital',
            'private equity', 'hedge funds', 'economic trends', 'financial analysis',
            'retirement planning', 'real estate investment', 'insurance',
            'tax strategies', 'behavioral finance'
        ]
    },
    Lifestyle: {
        emoji: '🌟',
        topics: [
            'food and drink', 'health and wellness', 'fitness',
            'personal development', 'spirituality', 'hobbies',
            'minimalism', 'fashion and style', 'interior design'
        ]
    },
    'Science & Tech': {
        emoji: '🔬',
        topics: [
            'ai', 'biohacking', 'machine learning', 'product design',
            'programming languages', 'vr/ar', 'product management',
            'robotics', 'fintech', 'data science', 'life sciences',
            'visual design', 'cybersecurity', 'quantum computing', 'biotech',
            'nanotechnology', 'space exploration', 'renewable energy', 'climate tech',
            'cloud computing', 'gaming technology', 'devops'
        ]
    },
    'Music & Audio': {
        emoji: '🎵',
        topics: [
            'classical music', 'jazz', 'rock', 'hip hop', 'electronic music',
            'music production', 'songwriting', 'music theory', 'music education',
            'music technology', 'audio engineering', 'sound design', 'music business',
            'music festivals', 'live performances', 'music streaming', 'vinyl collecting',
            'music history', 'world music', 'music instruments', 'music collaboration',
            'regional music genres', 'music therapy'
        ]
    },
    'Sports & Entertainment': {
        emoji: '🎭',
        topics: [
            'sports', 'movies', 'television', 'comedy',
            'books', 'arts and culture', 'dance',
            'festivals', 'events', 'concerts', 'soccer', 'basketball', 'cricket', 'tennis', 'golf', 'baseball',
            'volleyball', 'table tennis', 'badminton', 'rugby', 'hockey', 'swimming',
            'cycling', 'running', 'gym workouts', 'esports', 'theater productions', 'cultural heritage festivals'
        ]
    },
    'Professional Growth': {
        emoji: '📈',
        topics: [
            'leadership development', 'mentorship', 'project management', 'team collaboration',
            'innovation workshops', 'public speaking', 'industry networking', 'cross-functional collaboration',
            'career advancement', 'skill development', 'problem-solving', 'diversity and inclusion',
            'emotional intelligence'
        ]
    },
    'Health & Wellness': {
        emoji: '🧘‍♀️',
        topics: [
            'yoga', 'meditation', 'nutrition', 'mental health',
            'fitness challenges', 'home workouts', 'personal growth', 'wellness retreats',
            'healthy living', 'stress management', 'alternative medicine'
        ]
    },
    'Creative Interests': {
        emoji: '🎨',
        topics: [
            'photography', 'painting', 'writing', 'acting',
            'graphic design', 'arts and crafts', 'dancing', 'creative writing', 'film making',
            '3d art and animation', 'calligraphy'
        ]
    },
    'Social & Lifestyle': {
        emoji: '🍽️',
        topics: [
            'food and dining', 'wine tasting', 'coffee lovers', 'book clubs',
            'jeep travel', 'motorcycle riding', 'off-roading', 'camping',
            'adventure travel', 'urban exploration', 'travel blogging'
        ]
    },
    'Social Causes': {
        emoji: '🌍',
        topics: [
            'environmental sustainability', 'climate change', 'human rights',
            'social justice', 'education', 'healthcare access',
            'animal welfare', 'poverty alleviation', 'community development',
            'racial equality', 'mental health advocacy',
            'nonprofits', 'philanthropy', 'digital inclusion', 'sustainable fashion'
        ]
    },
    'Gaming & Virtual Worlds': {
        emoji: '🎮',
        topics: [
            'online multiplayer games', 'esports', 'virtual reality gaming', 'game design',
            'modding communities', 'gaming culture'
        ]
    },
    'History & Education': {
        emoji: '📜',
        topics: [
            'ancient history', 'modern history', 'cultural studies',
            'online learning platforms', 'tutoring', 'educational technology'
        ]
    },
    'Environment & Nature': {
        emoji: '🌱',
        topics: [
            'wildlife conservation', 'sustainable farming', 'urban gardening',
            'outdoor exploration', 'natural phenomena'
        ]
    },
    'DIY & Hobbies': {
        emoji: '🛠️',
        topics: [
            'home improvement', 'woodworking', 'electronics diy', 'upcycling projects',
            'jewelry making'
        ]
    }
};


export const rolesColorPlates = [
    '#BFD9DA', '#EEE4AF', '#ECD2C4', '#DBE2C6', '#DACEE0', '#F1D4A9', '#D6C5B6',
    '#B4D6B4', '#F3BBBB', '#E5F3BB', '#BDD2F3', '#ECC4D2', '#D3CFF', '#CFFEDA',
    '#F2CFFE', '#FBCDB3', '#BBF3E6'
];

const OnBoardingStep4: React.FC = () => {
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeInterest, setActiveInterests] = useState(interests);

    // Filter interests based on search query
    const filteredInterests = Object.entries(activeInterest).reduce((acc, [interest, data]) => {
        const filteredTopics = data.topics.filter(topic =>
            topic.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filteredTopics.length > 0) {
            acc[interest] = { ...data, topics: filteredTopics };
        }
        return acc;
    }, {} as typeof interests);

    useEffect(() => {
        // Check if there's an enterprise object in localStorage
        const enterpriseUser = localStorage.getItem('enterpriseParams');

        // If enterprise object exists, load enterprise goals, otherwise load regular goals
        if (enterpriseUser) {
            //set user enterprise param
            onboardingStore.setIsEnterprise(true);
            // setActiveInterests(enterpriseInterests);
        }

        setActiveInterests(interests);
    }, []);

    useEffect(() => {
        // Reaction to rerender the component when onboardingStore.interests changes
        const dispose = reaction(
            () => onboardingStore.interests.slice(), // Observes the 'interests' array
            () => {
                // Trigger a re-render by updating the local state
                setForceUpdate((prev) => prev + 1);
            }
        );

        // Clean up the reaction when the component unmounts
        return () => dispose();
    }, [forceUpdate]);

    const toggleTopic = (interest: string) => {
        onboardingStore.setInterests(interest);
    };

    const handlePrevStep = () => onboardingStore.setOnboardingPrevStep()

    const completeOnboarding = async () => {
        setIsLoading(true);
        if (onboardingStore.industry.length === 0) {
            toast.error('Please select your industry type to proceed.', { theme: "light" });
            return
        }

        const onboardingData = onboardingStore.getOnboardingData;

        try {
            const { data: updatedProfile } = await sendOnboardingData(onboardingData);
            const communityDataJSON = localStorage.getItem('communityData');

            if (communityDataJSON) {
              try {
                const communityData = JSON.parse(communityDataJSON);
                const { community_id, join_by } = communityData;
            
                if (community_id) {
                  const data = await authStore.joinCommunity(community_id, join_by || '');
                  toast.info(`You successfully added to the ${data.name} community!`, {
                    theme: 'light',
                    icon: '🔥',
                  });
                }
              } catch (error) {
                console.error('Failed to parse communityData:', error);
              }
            }

            if (authStore.currentUser) {
                localStorage.removeItem('enterpriseParams');
                authStore.currentUser.profile = updatedProfile
            }

        } catch (error) {
            console.error(error);
            toast.error('Something went wrong')
            // Handle errors, such as displaying an error message to the user
        }finally {
            setIsLoading(false);
        }
    };

    const getBGColor = (topic: string) => {
        return onboardingStore.interests.includes(topic)
            ? 'bg-primary-6000 text-white'
            : 'bg-gray-200 text-gray-800 border-gray-300 hover:bg-gray-300';
    };
    return (
        <CommonLayout
            index="5"
            onContinue={completeOnboarding}
            onBack={handlePrevStep}
            isLoading={isLoading}
        >
            <div className="w-full max-w-md mx-auto bg-white text-gray-900 p-6">
                <h2 className="text-xl font-semibold">🌟 Tell us your interests!</h2>
                <p className="text-gray-600 mb-4">Choose your top interests to personalize your experience.</p>

                {/* Selected Interests Summary */}
                {onboardingStore.interests.length > 0 && (
                    <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                        <h3 className="text-sm font-medium mb-2">Selected Interests ({onboardingStore.interests.length})</h3>
                        <div className="flex flex-wrap gap-2">
                            {onboardingStore.interests.map((interest) => (
                                <span
                                    key={interest}
                                    className="px-3 py-1 bg-primary-6000 text-white rounded-full text-sm flex items-center"
                                    onClick={() => toggleTopic(interest)}
                                >
                                    {interest}
                                    <span className="ml-2 cursor-pointer">×</span>
                                </span>
                            ))}
                        </div>
                    </div>
                )}

                {/* Search Bar */}
                <div className="relative mb-6">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                        type="text"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-primary-6000 focus:border-primary-6000"
                        placeholder="Search interests..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                <div className="space-y-4">
                    {Object.keys(filteredInterests).map((interest, i) => (
                        <Disclosure key={interest}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button
                                        className="flex justify-between w-full px-4 py-3 text-sm font-medium text-left text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-primary-6000 focus-visible:ring-opacity-75 transition-colors"
                                        style={{ backgroundColor: rolesColorPlates[i] }}
                                    >
                                        <span className="flex items-center">
                                            <span className="mr-2">{activeInterest[interest].emoji}</span>
                                            <span>{interest}</span>
                                            <span className="ml-2 text-xs text-gray-500">
                                                ({filteredInterests[interest].topics.length} topics)
                                            </span>
                                        </span>
                                        {open ? (
                                            <ChevronUpIcon className="w-5 h-5 text-gray-500" />
                                        ) : (
                                            <ChevronDownIcon className="w-5 h-5 text-gray-500" />
                                        )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-3 pb-2 bg-white border border-gray-200 rounded-lg mt-2">
                                        <div className="flex flex-wrap gap-2">
                                            {filteredInterests[interest].topics.map((topic) => (
                                                <button
                                                    key={topic}
                                                    className={`px-3 py-1.5 border rounded-full text-sm transition-colors ${getBGColor(topic)}`}
                                                    onClick={() => toggleTopic(topic)}
                                                >
                                                    {topic}
                                                </button>
                                            ))}
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </div>
            </div>
        </CommonLayout>
    );
};

export default observer(OnBoardingStep4);
