import { BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import FooterNav from "components/FooterNav";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import { Spinner } from "shared/Loader/Spinner";
import { notificationStore } from "stores/NotificationsStore";
import { buildNotificationMessage } from "./NotificationsBuilder";
import { useNavigate } from "react-router-dom";

export interface NotificationsProps {
  className?: string;
}

const Notifications: FC<NotificationsProps> = ({
  className = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (notificationStore?.notifications?.length === 0) {
      setIsLoading(true);
      notificationStore.pullNotifications().finally(() => setIsLoading(false));
    }
  }, []);

  const handleRemoveNotification = async (
    notificationId: string,
    e: React.MouseEvent
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await notificationStore.removeNotification(notificationId);
    } catch (error) {
      console.error("Failed to remove notification:", error);
    }
  };

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-3xl lg:px-8">
        <div className="py-8">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center">
              <BellIcon className="h-8 w-8 text-primary-600 dark:text-primary-500 mr-3" />
              <span className="text-2xl font-bold">Notifications</span>
            </div>
            {notificationStore?.notifications?.length > 0 && (
              <span className="text-sm font-medium text-neutral-500 dark:text-neutral-400">
                {notificationStore.notifications.length} notifications
              </span>
            )}
          </div>

          {isLoading ? (
            <div className="flex justify-center py-20">
              <Spinner size="lg" />
            </div>
          ) : (
            <div className="space-y-3">
              {Array.isArray(notificationStore?.notifications) &&
                notificationStore?.notifications.map((notification) => {
                  const notificationContent = buildNotificationMessage(notification);
                  return (
                    <div
                      key={notification.id}
                      className="bg-white dark:bg-neutral-800 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 relative group"
                      onClick={() => notificationContent.redirectUrl && navigate(notificationContent.redirectUrl)}
                      style={{ cursor: notificationContent.redirectUrl ? 'pointer' : 'default' }}
                    >
                      <div className="p-5 flex items-start space-x-4">
                        <div className="flex-shrink-0">
                          <Avatar
                            sizeClass="w-12 h-12"
                            imgUrl={notificationContent.imageUrl}
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-neutral-900 dark:text-neutral-100 leading-relaxed">
                            <span dangerouslySetInnerHTML={{ __html: notificationContent.text }} />
                          </p>
                          <p className="mt-1 text-xs font-medium text-neutral-500 dark:text-neutral-400">
                            {notificationContent.time}
                          </p>
                          {notificationContent.subtitle && (
                            <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300 line-clamp-2">
                              {notificationContent.subtitle}
                            </p>
                          )}
                        </div>
                        <button
                          onClick={(e) => handleRemoveNotification(notification.id, e)}
                          className="md:opacity-0 md:group-hover:opacity-100 absolute right-4 top-4 p-1.5 rounded-full bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 dark:hover:bg-neutral-600 transition-all duration-200"
                        >
                          <XMarkIcon className="h-4 w-4 text-neutral-500 dark:text-neutral-400" />
                        </button>
                      </div>
                    </div>
                  );
                })}

              {notificationStore?.notifications?.length === 0 && (
                <div className="text-center py-20">
                  <BellIcon className="mx-auto h-12 w-12 text-neutral-400 dark:text-neutral-600" />
                  <h3 className="mt-4 text-lg font-medium text-neutral-900 dark:text-neutral-100">
                    No notifications
                  </h3>
                  <p className="mt-2 text-sm text-neutral-500 dark:text-neutral-400">
                    You're all caught up! Check back later for new notifications.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <FooterNav />
    </div>
  );
};

export default observer(Notifications);
