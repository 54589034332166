import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { getProfileViewStatistics } from 'api/profile';
import CommonLayout from './CommonLayout';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { EyeIcon } from '@heroicons/react/24/outline';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ViewStatistics {
  date: string;
  count: number;
}

const ProfileViewsPage: FC = () => {
  const [viewStats, setViewStats] = useState<ViewStatistics[]>([]);
  const [timeRange, setTimeRange] = useState<number>(30); // Default to 30 days
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      setIsLoading(true);
      try {
        const stats = await getProfileViewStatistics(timeRange);
        setViewStats(stats);
      } catch (error) {
        console.error('Error fetching profile view statistics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, [timeRange]);

  const data = {
    labels: viewStats.map(stat => new Date(stat.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Profile Views',
        data: viewStats.map(stat => stat.count),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Profile Views Over Time',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const totalViews = viewStats.reduce((sum, stat) => sum + stat.count, 0);

  return (
    <div className="nc-ProfileViewsPage bg-neutral-50 min-h-screen">
      <Helmet>
        <title>Profile Views Statistics</title>
      </Helmet>
      <div className="bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 bg-white dark:bg-neutral-800">
        <div className="container">
        <div className="space-y-6 sm:space-y-8 max-w-4xl mx-auto">
          {/* Header */}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div>
              <h2 className="text-3xl font-semibold text-neutral-900">Profile Views</h2>
              <p className="text-neutral-600 mt-2">Track your profile's visibility and engagement</p>
            </div>
            <div className="flex gap-2 p-1 rounded-lg shadow-sm">
              {[
                { days: 7, label: '7D' },
                { days: 30, label: '30D' },
                { days: 90, label: '90D' },
              ].map(({ days, label }) => (
                <button
                  key={days}
                  className={`px-4 py-2 rounded-md transition-all duration-200 ${
                    timeRange === days
                      ? 'bg-blue-600 text-white shadow-sm'
                      : 'hover:bg-gray-100 text-gray-700'
                  }`}
                  onClick={() => setTimeRange(days)}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>

          {/* Stats Cards */}
          <div className="grid sm:grid-cols-2 gap-4">
            <div className="bg-white rounded-xl shadow-sm border border-neutral-200 p-6">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-primary-50 rounded-full">
                  <EyeIcon className="w-6 h-6 text-primary-600" />
                </div>
                <div>
                  <h3 className="text-2xl font-semibold text-neutral-900">{totalViews}</h3>
                  <p className="text-neutral-600">Total views</p>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-xl shadow-sm border border-neutral-200 p-6">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-green-50 rounded-full">
                  <EyeIcon className="w-6 h-6 text-green-600" />
                </div>
                <div>
                  <h3 className="text-2xl font-semibold text-neutral-900">
                    {viewStats.length > 0 ? Math.round(totalViews / viewStats.length) : 0}
                  </h3>
                  <p className="text-neutral-600">Average daily views</p>
                </div>
              </div>
            </div>
          </div>
            
          {/* Chart Card */}
          <div className="bg-white rounded-xl shadow-sm border border-neutral-200 p-6">
            {isLoading ? (
              <div className="h-[400px] flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600" />
              </div>
            ) : viewStats.length === 0 ? (
              <div className="h-[400px] flex items-center justify-center text-neutral-600">
                No data available for this time period
              </div>
            ) : (
              <div className="h-[400px]">
                <Line options={options} data={data} />
              </div>
            )}
          </div>
        </div>
        </div>
        </div>
        </div>
    </div>
  );
};

export default ProfileViewsPage; 