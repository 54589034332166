import { ConnectionResponse, approveConnection, cancelConnection, declineConnection, getConnection, requestConnection } from "api/connections";
import { UserProfileDTO } from "api/dto/get-user-data-by-id.dto";
import { trackProfileView } from "api/profile";
import { getMemberDataById, getUserByProfileId } from "api/user";
import FooterNav from "components/FooterNav";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import { authStore } from "stores/AuthStore";
import PlaceholderLargeH from '../../images/placeholder-large-h.png';
import ConnectionButtons from "./ConnectionSection";

export interface UserPageProps {
  className?: string;
}

type GoalIconMap = {
  'Bleisure': string;
  'Mentor others': string;
  'Raise funding': string;
  [key: string]: string;
};

const goalIcons: GoalIconMap = {
  'Bleisure': '🏝',
  'Mentor others': '🎓',
  'Raise funding': '💰',
  'default': '🎯'
};

const UserPage: FC<UserPageProps> = ({ className = "" }) => {
  const { userId, profileId } = useParams<{ userId: string, profileId: string }>();
  const [userData, setUserData] = useState<UserProfileDTO>();
  const [connection, setConnection] = useState<ConnectionResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if(userId){
          const response = await getMemberDataById(userId);
          setUserData(response.data);
        } else if(profileId){
          const response = await getUserByProfileId(profileId);
          setUserData(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const fetchUserConnection = async () => {
      try {
        const data = await getConnection(userId ?? '');
        setConnection(data);
      } catch (error) {
        console.error("Failed to fetch connection:", error);
      }
    };

    fetchUserConnection();
  }, [userId]);

  useEffect(() => {
    const trackView = async () => {
      if (!userId || userId === authStore.currentUser?.id) return;
      try {
        await trackProfileView(userId);
      } catch (error) {
        console.error("Failed to track profile view:", error);
      }
    };
    trackView();
  }, [userId]);

  const handleHandshake = async () => {
    setIsLoading(true);
    try {
      const response = await requestConnection(userData?.id ?? '');
      setConnection(response);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = async () => {
    setIsLoading(true);
    try {
      await cancelConnection(connection?.id ?? '');
      setConnection(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const response = await approveConnection(connection?.id ?? '');
      setConnection({ ...connection, ...response });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDecline = async () => {
    setIsLoading(true);
    try {
      const response = await declineConnection(connection?.id ?? '');
      setConnection(response);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>{userData?.profile?.fullName || 'Profile'} | Meet With</title>
      </Helmet>

      {/* Hero Section */}
      <div className="relative h-[300px] w-full">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${userData?.profile?.bgImg || PlaceholderLargeH})`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-40 backdrop-blur-[2px]"></div>
        </div>
      </div>

      <div className="container relative -mt-[100px] pb-20">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Profile Card */}
          <div className="lg:col-span-1">
            <div className="bg-white dark:bg-neutral-900 rounded-3xl shadow-xl overflow-hidden">
              <div className="p-6 text-center">
                <Avatar
                  sizeClass="w-28 h-28"
                  imgUrl={userData?.profile?.avatar ?? ''}
                  userName={userData?.profile?.fullName}
                  containerClassName="ring-4 ring-white dark:ring-neutral-900 shadow-2xl mx-auto"
                />
                <h1 className="text-2xl font-bold mt-4 text-neutral-900 dark:text-white">
                  {userData?.profile?.fullName}
                </h1>
                <div className="text-sm text-neutral-500 dark:text-neutral-400 mt-1 space-y-1">
                  <p className="font-medium">{userData?.profile?.role}</p>
                  {userData?.profile?.companyName && (
                    <p>@ {userData?.profile?.companyName}</p>
                  )}
                  <p className="font-medium">{userData?.profile?.industry}</p>
                </div>

                <div className="mt-6">
                  <ConnectionButtons
                    connection={connection}
                    user={authStore.currentUser}
                    onHandshake={handleHandshake}
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    onDecline={handleDecline}
                    isLoading={isLoading}
                  />
                </div>
              </div>

              <div className="border-t border-neutral-200 dark:border-neutral-700 p-6 space-y-6">
                {/* Location */}
                <div>
                  <h3 className="text-sm font-semibold text-neutral-700 dark:text-neutral-300 mb-2">Location</h3>
                  <div className="flex items-center text-neutral-500 dark:text-neutral-400">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <span>{userData?.profile?.city}, {userData?.profile?.country}</span>
                  </div>
                </div>

                {/* Languages */}
                <div>
                  <h3 className="text-sm font-semibold text-neutral-700 dark:text-neutral-300 mb-2">Languages</h3>
                  <div className="flex flex-wrap gap-2">
                    {userData?.profile?.languages?.map((language: string, index: number) => (
                      <span
                        key={index}
                        className="px-2.5 py-1 text-xs font-medium bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300 rounded-full"
                      >
                        {language}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Bio */}
                {userData?.profile?.bio && (
                  <div>
                    <h3 className="text-sm font-semibold text-neutral-700 dark:text-neutral-300 mb-2">About</h3>
                    <p className="text-sm text-neutral-600 dark:text-neutral-400 whitespace-pre-line">
                      {userData.profile.bio}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Right Column - Goals & Interests */}
          <div className="lg:col-span-2 space-y-8">
            {/* Goals Section */}
            <div className="bg-white dark:bg-neutral-900 rounded-3xl shadow-xl p-6">
              <h2 className="text-xl font-bold text-neutral-900 dark:text-white mb-6">Goals</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {userData?.profile?.goals?.map((goal: string, index: number) => (
                  <div
                    key={index}
                    className="flex items-center p-4 bg-neutral-50 dark:bg-neutral-800 rounded-2xl transition-all hover:shadow-md"
                  >
                    <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center bg-primary-100 dark:bg-primary-900 rounded-xl">
                      <span className="text-xl">{goalIcons[goal] || goalIcons.default}</span>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-base font-semibold text-neutral-900 dark:text-white">{goal}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Interests Section */}
            <div className="bg-white dark:bg-neutral-900 rounded-3xl shadow-xl p-6">
              <h2 className="text-xl font-bold text-neutral-900 dark:text-white mb-6">Interests</h2>
              <div className="flex flex-wrap gap-3">
                {userData?.profile?.interests?.map((interest: string, index: number) => (
                  <div
                    key={index}
                    className="px-4 py-2 bg-neutral-100 dark:bg-neutral-800 rounded-xl text-neutral-700 dark:text-neutral-300 text-sm font-medium transition-all hover:shadow-md"
                  >
                    {interest}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterNav />
    </div>
  );
};

export default observer(UserPage);