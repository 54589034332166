import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SectionGridActivityBox from '../../components/SectionGridActivityBox/SectionGridActivityBox';
import { ActivityResponse } from 'interface/activity';
import { getActivitiesByCommunityId } from '../../api/activities';
import Heading from 'components/Heading/Heading';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

interface CommunityActivitiesContainerProps {
    communityId: string;
}

const CommunityActivitiesContainer = () => {
    const [searchParams] = useSearchParams();
    const communityId = searchParams.get('community_id');
    const [activities, setActivities] = useState<ActivityResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchActivities = async () => {
            if (!communityId) return;

            try {
                const response = await getActivitiesByCommunityId(communityId);
                setActivities(response.data.items);
            } catch (error) {
                console.error('Error fetching community activities:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, [communityId]);

    if (loading) {
        return <div className="p-4">Loading activities...</div>;
    }

    return (
        <div className="container mx-auto mt-4">
            <button
                onClick={handleBack}
                className="flex items-center text-neutral-600 dark:text-neutral-300 hover:text-neutral-800 dark:hover:text-neutral-100 mb-6"
            >
                <ChevronLeftIcon className="h-5 w-5 mr-2" />
                Back
            </button>
            <Heading desc="Discover the activities of this community" >Community Activities</Heading>
            <SectionGridActivityBox boxCard="box2" activities={activities} communityId={communityId || ''} />
        </div>
    );
}

export default CommunityActivitiesContainer;