import { ProfessionalProfileDto } from "api/professionalLocals";
import { ActivityResponse } from "interface/activity";
import Heading from "components/Heading/Heading";
import NoResultCard from "components/NoResult/GenericNoResultCard";
import SectionSliderDiscoverLocals from "components/SectionSliderDiscoverLocals/SectionSliderDiscoverLocals";
import SectionSliderMatchingBusinessTravelers from "components/SectionSliderMatchingBusinessTravelers/SectionSliderMatchingBusinessTravelers";
import { TripDetails } from "interface/trip.interface";
import SectionSliderActivities from "components/SectionSliderActivities/SectionSliderActivities";

interface GlobalSectionProps {
  professionalLocals: ProfessionalProfileDto[];
  globalTravelers: TripDetails[] | null;
  // activities: ActivityResponse[];
  isLoading?: boolean;
}

export const GlobalSection: React.FC<GlobalSectionProps> = ({
  professionalLocals,
  globalTravelers,
  // activities,
  isLoading = false
}) => {

  return (
    <>
      <Heading 
        className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50" 
        desc={""} 
        viewAll={{ label: "View all", href: "/professional/global" }}
      >
        Meet Global Professionals
      </Heading>
      
      <SectionSliderDiscoverLocals
        likeMindedLocals={professionalLocals}
        uniqueClassName="GlobalMatches"
        isLoading={isLoading}
      />

      <Heading
        className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50 mt-10"
        desc=''
        viewAll={{ label: "View all", href: "/professional/global-arrivals" }}
      >
        Traveling this month
      </Heading>

      {globalTravelers?.length ? (
        <SectionSliderMatchingBusinessTravelers
          userMatches={globalTravelers}
          uniqueClassName="GlobalArrivalsNextMonth"
          isLoading={isLoading}
        />
      ) : (
        <NoResultCard displayName="No matching global business travelers... yet." />
      )}

      {/* <Heading
        className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50 mt-10"
        desc=''
        viewAll={{ label: "View all", href: "/activities/global" }}
      >
        Global Activities
      </Heading>

      {activities?.length ? (
        <SectionSliderActivities
          activities={activities}
          uniqueClassName="GlobalActivities"
          isLoading={isLoading}
        />
      ) : (
        <NoResultCard displayName="No global activities available... yet." />
      )} */}
    </>
  );
}; 