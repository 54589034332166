import React from 'react';
import { CommunityData } from 'interface/communities';
import HeroSection from './HeroSection';
import QuickActions from './QuickActions';
import IndustryCompositionChart from '../IndustryCompositionChart';
import RecentMembersFeed from '../RecentMembersFeed';
import DigitalBulletinBoard from '../Widgets/DigitalBulletinBoard/DigitalBulletinBoard';
import CommunityEssentials from './CommunityEssentials';
import FooterNav from 'components/FooterNav';

interface SpotifyLayoutProps {
    communityData: CommunityData | null;
}

const SpotifyLayout: React.FC<SpotifyLayoutProps> = ({ communityData }) => {
    if (!communityData) return null;

    return (
        <div className="min-h-screen bg-white">
            {/* Hero Section */}
            <HeroSection communityData={communityData} />

            {/* Quick Actions */}
            <QuickActions communityId={communityData.id} />

            {/* Main Content */}
            <div className="px-4 py-6 sm:px-6 lg:px-8 space-y-8">
                {/* Digital Bulletin Board */}
                <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
                    <DigitalBulletinBoard communityId={communityData.id} className="border-none shadow-none" />
                </div>

                {/* Middle Column - Recent Members */}
                <div className="space-y-8">
                    <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-base font-semibold text-gray-900">New Members</h2>
                            <div className="p-2 rounded-full bg-green-50">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                                </svg>
                            </div>
                        </div>
                        <RecentMembersFeed communityData={communityData} maxMembers={5} />
                    </div>
                </div>

                {/* Three Column Layout for Desktop */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {/* Left Column - Industry Composition */}
                    <div className="space-y-8">
                        <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
                            <div className="flex items-center justify-between mb-4">
                                <h2 className="text-xl font-semibold text-gray-900">Industry Composition</h2>
                                <div className="p-2 rounded-full bg-purple-50">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-600" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                                        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                                    </svg>
                                </div>
                            </div>
                            <IndustryCompositionChart communityData={communityData} />
                        </div>
                    </div>



                    {/* Right Column - Community Essentials */}
                    <div className="space-y-8">
                        <CommunityEssentials community={communityData} />
                    </div>
                </div>
            </div>
            <FooterNav />
        </div>
    );
};

export default SpotifyLayout; 