import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { CommunityData } from 'interface/communities';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IndustryCompositionChartProps {
  communityData: CommunityData | null;
}

// Define a color palette for the pie chart segments
const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8',
  '#82ca9d', '#ffc658', '#ff7c43', '#665191', '#a05195'
];

const BACKGROUND_COLORS = COLORS.map(color => `${color}CC`); // Adding 80% opacity
const BORDER_COLORS = COLORS;

// Maximum number of industries to show individually before grouping into "Others"
const MAX_INDUSTRIES_SHOWN = 5;

const IndustryCompositionChart: React.FC<IndustryCompositionChartProps> = ({ communityData }) => {
  // Aggregate industry data
  const industryData = useMemo(() => {
    if (!communityData?.communityUsers) return { labels: [], values: [], percentages: [], originalData: [] };

    const industryCount: { [key: string]: number } = {};
    let totalUsers = 0;

    // Count users by industry
    communityData.communityUsers.forEach(user => {
      if (user.profile?.industry) {
        industryCount[user.profile.industry] = (industryCount[user.profile.industry] || 0) + 1;
        totalUsers++;
      }
    });

    // Convert to array and sort by count (descending)
    const entries = Object.entries(industryCount)
      .map(([name, value]) => ({
        name,
        value,
        percentage: ((value / totalUsers) * 100)
      }))
      .sort((a, b) => b.percentage - a.percentage);

    // Group smaller segments into "Others" if there are too many industries
    let processedEntries = entries;
    if (entries.length > MAX_INDUSTRIES_SHOWN) {
      const mainIndustries = entries.slice(0, MAX_INDUSTRIES_SHOWN - 1);
      const otherIndustries = entries.slice(MAX_INDUSTRIES_SHOWN - 1);
      
      const othersValue = otherIndustries.reduce((sum, entry) => sum + entry.value, 0);
      const othersPercentage = otherIndustries.reduce((sum, entry) => sum + entry.percentage, 0);
      
      processedEntries = [
        ...mainIndustries,
        {
          name: 'Others',
          value: othersValue,
          percentage: othersPercentage
        }
      ];
    }

    return {
      labels: processedEntries.map(entry => entry.name),
      values: processedEntries.map(entry => entry.value),
      percentages: processedEntries.map(entry => entry.percentage.toFixed(1)),
      originalData: entries // Keep original data for detailed breakdown
    };
  }, [communityData]);

  if (!industryData.labels.length) {
    return null;
  }

  const chartData = {
    labels: industryData.labels,
    datasets: [
      {
        data: industryData.values,
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend as we're using custom one
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const percentage = industryData.percentages[context.dataIndex];
            return `${label}: ${value} members (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <div className="listingSection__wrap">
      <h2 className="text-2xl font-semibold">Industry Composition</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>
      
      <div className="flex flex-col lg:flex-row items-start justify-between gap-6">
        {/* Chart Container */}
        <div className="w-full lg:w-1/2 relative" style={{ height: '300px' }}>
          <Pie data={chartData} options={options} />
        </div>
        
        {/* Legend and Breakdown */}
        <div className="w-full lg:w-1/2">
          <div className="bg-gray-50 dark:bg-neutral-800 rounded-lg p-4">
            <h3 className="text-lg font-medium mb-3">Industry Breakdown</h3>
            
            {/* Scrollable list for many industries */}
            <div className="space-y-2 max-h-[250px] overflow-y-auto custom-scrollbar">
              {/* Show all original data in the breakdown */}
              {industryData.originalData.map((industry, index) => (
                <div key={industry.name} className="flex items-center justify-between py-1">
                  <div className="flex items-center min-w-0">
                    <div
                      className="w-3 h-3 flex-shrink-0 rounded-full mr-2"
                      style={{ 
                        backgroundColor: index < MAX_INDUSTRIES_SHOWN 
                          ? COLORS[index % COLORS.length] 
                          : '#808080' 
                      }}
                    />
                    <span className="text-sm truncate">{industry.name}</span>
                  </div>
                  <div className="flex items-center gap-2 flex-shrink-0 ml-2">
                    <span className="text-sm text-gray-500">{industry.value} members</span>
                    <span className="text-sm font-medium">
                      {industry.percentage.toFixed(1)}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryCompositionChart; 