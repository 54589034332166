import React from 'react';

const Message = ({ text, date, me, recipientAvatar }: { text: string, date: string, me: boolean, recipientAvatar: string }) => {
    return (
        <div className={`flex items-start gap-2 mb-4 ${me ? 'flex-row-reverse' : ''}`}>
            {!me && (
                <div className="relative flex-shrink-0">
                    <img 
                        className="w-10 h-10 rounded-full object-cover shadow-sm" 
                        src={recipientAvatar} 
                        alt="User avatar"
                        referrerPolicy='no-referrer'
                    />
                    <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></span>
                </div>
            )}
            <div className={`flex flex-col ${me ? 'items-end' : 'items-start'} max-w-[70%]`}>
                <div 
                    className={`
                        px-4 py-2 rounded-2xl break-words
                        ${me ? 
                            'bg-blue-600 text-white rounded-tr-none shadow-sm' : 
                            'bg-gray-100 text-gray-800 rounded-tl-none shadow-sm'
                        }
                    `}
                >
                    {text}
                </div>
                <span className="text-xs text-gray-500 mt-1 px-1">
                    {date}
                </span>
            </div>
        </div>
    );
};

export default Message;
