import { getMatches, submitUserMatchInteraction } from "api/matches";
import FooterNav from "components/FooterNav";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Lottie from 'react-lottie';
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import swipeLottie from "../../images/lottie/swipe.json";
import 'swiper/css';

import Avatar from "shared/Avatar/Avatar";
import { useNavigate } from "react-router-dom";

const MeetWithPage: React.FC = () => {
    const cardRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const [matches, setMatches] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSwiping, setIsSwiping] = useState(false);
    const [startX, setStartX] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [showLottie, setShowLottie] = useState(true);
    const [expandedInterests, setExpandedInterests] = useState(false);
    const [expandedLanguages, setExpandedLanguages] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLottie(false);
        }, 4000);

        return () => clearTimeout(timer); // Clean up the timer if the component unmounts
    }, []);

    useEffect(() => {
        const fetchMatches = async () => {
            setLoading(true);
            try {
                const matches = await getMatches();
                setMatches(matches.data);
            } catch (error) {
                console.error('Failed to fetch matches:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, []);

    const handleUserMatchAction = async (userIndex: number, action: 'L' | 'D'): Promise<boolean> => {
        const targetUser = matches[userIndex];

        if (!targetUser) {
            console.error('No user found at the provided index');
            return false
        }

        try {
            const { data } = await submitUserMatchInteraction(targetUser.id, action)
            if (data) {
                navigate(`/user/${targetUser.id}`);
            }
        } catch (error) {
            console.error('An error occurred while sending the user action to the backend:', error);
            toast.error('An error occurred. Please try again later.');
        } finally {
            return false;
        }
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setStartX(e.touches[0].clientX);
        setIsSwiping(true);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!isSwiping) return;

        const currentX = e.touches[0].clientX;
        const diffX = currentX - startX;

        setCurrentTranslate(diffX);
    };

    const handleTouchEnd = () => {
        setIsSwiping(false);

        if (currentTranslate > 100) {
            handleSwipe('left');
        } else if (currentTranslate < -100) {
            handleSwipe('right');
        } else {
            setCurrentTranslate(0);
        }
    };

    const handleSwipe = async (direction: string) => {
        const person = matches[currentIndex];

        if (direction === 'right') {
            await handleUserMatchAction(currentIndex, 'L');
        } else if (direction === 'left') {
            await handleUserMatchAction(currentIndex, 'D');
        }
        setMatches(prevMatches => prevMatches.filter((people) => people.id !== person.id));
        setCurrentIndex((prevIndex) => 0);
        setCurrentTranslate(0);
    };

    const handleSwipeAnimation = (direction: 'left' | 'right') => {
        const card = cardRef.current;
        if (!card) return;

        // Add transform and opacity animation
        card.style.transition = 'transform 0.5s, opacity 0.5s';
        card.style.transform = `translate(${direction === 'right' ? '150%' : '-150%'}) rotate(${direction === 'right' ? '30deg' : '-30deg'})`;
        card.style.opacity = '0';

        // After animation, handle the swipe logic
        setTimeout(async () => {
            await handleSwipe(direction);
            // Reset card position for next card
            card.style.transition = 'none';
            card.style.transform = 'translate(0) rotate(0)';
            card.style.opacity = '1';
        }, 500);
    };

    const toggleLanguages = () => setExpandedLanguages(!expandedLanguages);

    if (currentIndex >= matches.length) {
        return <div className="text-center mt-20">No more matches</div>;
    }

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: swipeLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    
    return (
        <div className="nc-MeetWithPage min-h-screen mb-40">
            <Helmet>
                <title>Meet With - Swipe For Connections</title>
            </Helmet>

            {/* Header Section */}
            <header className="shadow-sm py-6 mb-8">
                <div className="container mx-auto px-4">
                    <h1 className="text-3xl font-bold text-center text-gray-900">
                        Discover Connections
                    </h1>
                    <p className="text-center text-gray-600 mt-2 max-w-2xl mx-auto">
                        Swipe through profiles to find your next business connection or mentor
                    </p>
                </div>
            </header>

            <div className="container relative pb-20 px-4">
                {loading ? (
                    <div className="flex justify-center items-center h-[60vh]">
                        <BeatLoader color="#123abc" />
                    </div>
                ) : matches.length === 0 ? (
                    <div className="flex flex-col items-center justify-center h-[60vh] text-xl text-gray-600">
                        <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                        No more matches available
                    </div>
                ) : (
                    <div className="relative max-w-md mx-auto">
                        {/* Desktop Buttons */}
                        <div className="flex justify-center gap-6 mb-6">
                            <button 
                                onClick={() => handleSwipeAnimation('left')}
                                className="p-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-110 "
                                aria-label="Pass"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <button 
                                onClick={() => handleSwipeAnimation('right')}
                                className="p-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-110 "
                                aria-label="Like"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </button>
                        </div>

                        {/* Card Stack */}
                        <div 
                            className="relative h-[calc(100vh-350px)] touch-pan-y"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                        >
                            {matches.map((person, index) => (
                                <div
                                    key={person.id}
                                    ref={index === currentIndex ? cardRef : null}
                                    className={`absolute w-full transition-all duration-300 transform ${index === currentIndex ? 'z-1000 scale-105' : 'z-0 hidden'}`}
                                    style={{
                                        transform: index === currentIndex ? 
                                            `translateX(${currentTranslate}px) rotate(${currentTranslate * 0.1}deg)` : 
                                            undefined
                                    }}
                                >
                                    <div className="rounded-xl shadow-xl overflow-hidden p-6">
                                        <div className="flex flex-col items-center">
                                            <Avatar
                                                imgUrl={person.profile?.avatar || ''}
                                                userName={person.profile?.fullName || ''}
                                                sizeClass="w-32 h-32 md:w-40 md:h-40 rounded-full object-cover mb-6"
                                            />
                                            <h3 className="text-2xl font-bold text-gray-900">
                                                {person.profile?.fullName || ''}
                                            </h3>
                                            <p className="text-lg text-gray-700 font-medium mt-2">
                                                {person.profile?.role || ''} {person.profile?.companyName ? ` @ ${person.profile.companyName}` : ''}
                                            </p>
                                            <p className="text-gray-600">
                                                {person.profile?.bio || ''}
                                            </p>    
                                            <div className="flex items-center gap-2 text-gray-600 mt-2">
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                                <span>{person.profile?.city || ''}, {person.profile?.country || ''}</span>
                                            </div>
                                        </div>

                                        <div className="mt-8">
                                            <h4 className="text-lg font-bold text-gray-900 mb-3">Interests</h4>
                                            <div className="flex flex-wrap gap-1 mt-4">
                                                {person?.profile?.interests?.slice(0, expandedInterests ? person.profile.interests.length : 3).map((interest: string, index: number) => (
                                                    <span
                                                        key={index}
                                                        className="px-3 py-1 bg-blue-100 text-blue-600 text-sm font-medium rounded-full dark:bg-blue-900 dark:text-blue-300"
                                                    >
                                                        {interest}
                                                    </span>
                                                ))}
                                                {person?.profile?.interests?.length && person.profile.interests.length > 3 && (
                                                    <button
                                                        onClick={() => setExpandedInterests(prev => !prev)}
                                                        className="px-3 py-1 bg-gray-100 text-gray-600 text-sm font-medium rounded-full dark:bg-gray-800 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
                                                    >
                                                        {expandedInterests ? "Show less" : "+" + (person.profile.interests.length - 3) + " more"}
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                            <h4 className="text-lg font-bold text-gray-900 mb-3">Languages</h4>
                                            <div className="space-y-3">
                                                <ul className="flex flex-wrap gap-2">
                                                    {person?.profile?.languages?.slice(0, expandedLanguages ? undefined : 5).map((language: string, i: number) => (
                                                        <li
                                                            key={i}
                                                            className="inline-block px-4 py-2 bg-green-50 text-green-600 rounded-full text-sm font-medium hover:bg-green-100 transition-colors"
                                                        >
                                                            {language}
                                                        </li>
                                                    ))}
                                                </ul>
                                                {person?.profile?.languages?.length > 5 && (
                                                    <button
                                                        onClick={toggleLanguages}
                                                        className="text-green-600 hover:text-green-700 text-sm font-medium"
                                                    >
                                                        {expandedLanguages ? 'Show Less' : `Show ${person.profile.languages.length - 5} More`}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Swipe Tutorial Overlay */}
                        {showLottie && (
                            <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                                <div className="p-6 rounded-lg text-white text-center">
                                    <Lottie options={lottieOptions} height={100} width={200} />
                                    <p className="mt-4 text-lg">
                                        {window.innerWidth >= 768 ? 
                                            "Click buttons to accept or pass" : 
                                            "Swipe right to accept, left to pass"}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <FooterNav />
        </div>
    );
};

export default MeetWithPage;
