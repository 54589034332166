import React from 'react';
import { CommunityData } from 'interface/communities';

interface CommunityEssentialsProps {
  community: CommunityData;
}

const CommunityEssentials: React.FC<CommunityEssentialsProps> = ({ community }) => {
  return (
    <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
      <div className="space-y-6">
        {/* Community Logo */}
        {community.logo && (
          <div className="flex justify-center">
            <img
              src={community.logo}
              alt={community.name}
              className="w-32 h-32 object-fit rounded-lg"
            />
          </div>
        )}

        {/* Description */}
        {community.description && (
          <div>
            <h3 className="text-lg font-semibold mb-2">About</h3>
            <p className="text-gray-600">{community.description}</p>
          </div>
        )}

        {/* Location */}
        {(community.city || community.country) && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Location</h3>
            <p className="text-gray-600">
              {[community.city, community.country].filter(Boolean).join(', ')}
            </p>
          </div>
        )}

        {/* Contact Information */}
        {(community.contactEmail || community.phone) && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Contact</h3>
            {community.contactEmail && (
              <p className="text-gray-600 mb-1">Email: {community.contactEmail}</p>
            )}
            {community.phone && (
              <p className="text-gray-600">Phone: {community.phone}</p>
            )}
          </div>
        )}

        {/* External Links */}
        {(community.website || community.linkedin || community.facebook || community.instagram) && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Links</h3>
            <div className="space-y-1">
              {community.website && (
                <a
                  href={community.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  Website
                </a>
              )}
              {community.linkedin && (
                <a
                  href={community.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  LinkedIn
                </a>
              )}
              {community.facebook && (
                <a
                  href={community.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  Facebook
                </a>
              )}
              {community.instagram && (
                <a
                  href={community.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  Instagram
                </a>
              )}
            </div>
          </div>
        )}

        {/* Amenities */}
        {community.amenities && community.amenities.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Amenities</h3>
            <div className="flex flex-wrap gap-2">
              {community.amenities.map((amenity, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                >
                  {amenity}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityEssentials; 