import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Game, GameParticipation, GameStatus, GameType } from '../../../interface/games';
import * as gamesApi from '../../../api/games';
import WordleGame from './WordleGame';
import GuessWhoGame from './GuessWhoGame';
import GameLeaderboard from './GameLeaderboard';
import BlueLoader from 'shared/Loader/BlueLoader';
import { authStore } from 'stores/AuthStore';

interface GameCenterProps {
  communityId: string;
}

export const GameCenter: React.FC<GameCenterProps> = observer(({ communityId }) => {
  const [activeGame, setActiveGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [shouldRefreshLeaderboard, setShouldRefreshLeaderboard] = useState(false);
  const currentUser = authStore.currentUser;

  const fetchActiveGame = async () => {
    try {
      const game = await gamesApi.getActiveGame(communityId);
      setActiveGame(game);
      if (game && game?.participations) {
        const myParticipation = game?.participations.find((p: GameParticipation) => p.user.id === currentUser?.id);
        if (myParticipation?.hasCompleted) {
          setShouldRefreshLeaderboard(true);
        }
      }
      setError(null);
    } catch (err) {
      setError('Failed to fetch active game');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveGame();
  }, [communityId]);

  const handleStartGame = async (type: GameType) => {
    try {
      setLoading(true);
      const game = await gamesApi.startGame(communityId, type);
      const participation = await gamesApi.joinGame(game.id);
      game.participations = [participation];
      setActiveGame(game);
      setShouldRefreshLeaderboard(false);
      setError(null);
    } catch (err) {
      setError('Failed to start game');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <BlueLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        {error}
      </div>
    );
  }

  if (!activeGame) {
    return (
      <div className="p-6 bg-white rounded-lg shadow">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Game Center</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <button
            onClick={() => handleStartGame(GameType.WORDLE)}
            className="p-6 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
          >
            <h3 className="text-xl font-semibold mb-2">Wordle</h3>
            <p className="text-gray-600">Guess the hidden word in 6 tries!</p>
          </button>
          <button
            onClick={() => handleStartGame(GameType.GUESS_WHO)}
            className="p-6 bg-green-50 rounded-lg hover:bg-green-100 transition-colors"
          >
            <h3 className="text-xl font-semibold mb-2">Guess Who?</h3>
            <p className="text-gray-600">Identify the community member from hints!</p>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-800">
            {activeGame.type === GameType.WORDLE ? 'Wordle' : 'Guess Who?'}
          </h2>
          <span className={`px-3 py-1 rounded-full text-sm ${activeGame.status === GameStatus.ACTIVE
              ? 'bg-green-100 text-green-800'
              : 'bg-gray-100 text-gray-800'
            }`}>
            {activeGame.status}
          </span>
        </div>
        <p className="text-gray-600 mt-2">
          Game ends at {new Date(activeGame.endTime).toLocaleString()}
        </p>
      </div>

      {activeGame.type === GameType.WORDLE ? (
        <WordleGame game={activeGame} onUpdate={fetchActiveGame} />
      ) : (
        <GuessWhoGame game={activeGame} onUpdate={fetchActiveGame} />
      )}

      <div className="mt-8">
        <GameLeaderboard gameId={activeGame.id} key={shouldRefreshLeaderboard ? 'refresh' : 'normal'} />
      </div>
    </div>
  );
}); 