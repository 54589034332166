import { fetchCommunityData } from 'api/communities';
import { addDoc, collection, limit, onSnapshot, orderBy, query, serverTimestamp, startAfter, getDocs } from 'firebase/firestore';
import { CommunityData } from 'interface/communities';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Avatar from 'shared/Avatar/Avatar';
import { authStore } from 'stores/AuthStore';
import { firestore } from '../../firebase';
import './CommunityGroupChat.css';

const CommunityGroupChat = () => {
    const location = useLocation();
    const [messages, setMessages] = useState<any[]>([]);
    const [newMessage, setNewMessage] = useState('');
    const [lastVisible, setLastVisible] = useState<any>();
    const [hasMore, setHasMore] = useState(true);
    const [community, setCommunity] = useState<CommunityData | null>(null);
    const messageListRef = useRef<HTMLDivElement>(null);
    const isLoadingMore = useRef(false);
    const prevScrollHeight = useRef<number>(0);
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const currentUser = authStore.currentUser;

    const community_id = queryParams.get('community_id');

    useEffect(() => {
        if (community_id) {
            fetchCommunityData(community_id).then((response) => {
                const { data } = response;

                if (data.communityUsers?.length === 0) {
                    toast.info('Join the community to chat with other members', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    return;
                }

                const communityUser = data.communityUsers?.find((communityUser) => communityUser.user?.id === currentUser?.id);
                if (communityUser) {
                    setCommunity(data);
                    loadInitialMessages();
                } else {
                    toast.error('You are not part of this community', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        icon: '😕'
                    });
                    navigate('/');
                }
            });
        }
    }, [community_id, currentUser?.id, navigate]);

    const loadInitialMessages = async () => {
        const q = query(
            collection(firestore, 'communities', community_id || '', 'messages'),
            orderBy('timestamp', 'desc'),
            limit(20)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                createInitialMessage();
            } else {
                const messagesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessages(messagesData.reverse());                
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                setHasMore(snapshot.docs.length === 20);
                scrollToBottom(); // Ensure chat starts at the bottom
            }
        });

        return () => unsubscribe();
    };

    const createInitialMessage = async () => {
        const messagesCollection = collection(firestore, 'communities', community_id || '', 'messages');
        await addDoc(messagesCollection, {
            text: 'Welcome to the chat!',
            uid: 'system',
            displayName: 'System',
            timestamp: serverTimestamp(),
        });
    };

    const loadMoreMessages = async () => {
        console.log('Loading more messages...', {
            lastVisible: lastVisible,
            hasMore
        });
        
        if (!lastVisible || !hasMore || !community_id) return;

        try {            
            const q = query(
                collection(firestore, 'communities', community_id, 'messages'),
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(20)
            );

            const snapshot = await getDocs(q);
            
            if (!snapshot.empty) {
                const newMessages = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                
                setMessages(prev => [...newMessages.reverse(), ...prev]);
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                setHasMore(snapshot.docs.length === 20);
                
            } else {
                setHasMore(false);
                console.log('No more messages to load');
            }
        } catch (error) {
            console.error('Error loading more messages:', error);
            toast.error('Failed to load more messages');
            isLoadingMore.current = false;
        }
    };

    const handleSendMessage = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        if (!currentUser?.id || !currentUser?.profile) {
            console.error('User data is missing.');
            return;
        }

        const { id, profile } = currentUser;
        await addDoc(collection(firestore, 'communities', community_id || '', 'messages'), {
            text: newMessage,
            id,
            displayName: profile.fullName,
            timestamp: serverTimestamp(),
            avatar: profile.avatar,
        });

        setNewMessage('');
        scrollToBottom();
    };

    const scrollToBottom = () => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        
        const messageList = messageListRef.current;
        if (!messageList) return;

        const handleScroll = async () => {
            console.log('handleScroll');
            
            // Check if we're already loading more messages
            if (isLoadingMore.current || !hasMore) return;

            // Calculate scroll position
            const scrollTop = messageList.scrollTop;
            const scrollHeight = messageList.scrollHeight;
            const clientHeight = messageList.clientHeight;

            // Load more when user scrolls near the top (within 100px)
            if (scrollTop < 100) {
                console.log('Triggering load more...', {
                    scrollTop,
                    scrollHeight,
                    clientHeight
                });
                
                isLoadingMore.current = true;
                prevScrollHeight.current = scrollHeight;

                await loadMoreMessages();

                // Maintain scroll position after loading more messages
                requestAnimationFrame(() => {
                    if (messageList) {
                        const newScrollHeight = messageList.scrollHeight;
                        const scrollDiff = newScrollHeight - prevScrollHeight.current;
                        messageList.scrollTop = scrollDiff;
                    }
                    isLoadingMore.current = false;
                });
            }
        };

        // Add scroll event listener
        messageList.addEventListener('scroll', handleScroll);
        
        // Cleanup
        return () => {
            if (messageList) {
                messageList.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore, lastVisible]); // Only re-run if hasMore changes

    // useEffect(() => {
    //     if (messages.length > 0) {
    //         scrollToBottom();
    //     }
    // }, [messages]); // Scroll when messages update

    return (
        <div className="h-screen flex flex-col">
            <div className="flex-1 flex flex-col max-w-6xl mx-auto w-full overflow-hidden">
                <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200 dark:border-neutral-700">
                    <div className="flex items-center space-x-4">
                        <button
                            onClick={() => navigate(-1)}
                            className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full transition-colors"
                        >
                            <i className="las la-arrow-left text-xl"></i>
                        </button>
                        <Avatar 
                            imgUrl={community?.logo || ''} 
                            containerClassName="w-10 h-10 rounded-full"
                        />
                        <div>
                            <h2 className="font-semibold text-gray-900 dark:text-white">
                                {community?.name}
                            </h2>
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                {community?.communityUsers?.length} members
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <button className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full transition-colors">
                            <i className="las la-phone text-xl"></i>
                        </button>
                        <button className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full transition-colors">
                            <i className="las la-video text-xl"></i>
                        </button>
                        <button className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full transition-colors">
                            <i className="las la-info-circle text-xl"></i>
                        </button>
                    </div>
                </div>

                <div 
                    ref={messageListRef}
                    className="flex-1 overflow-y-auto min-h-0"
                >
                    <div className="p-4 space-y-4">
                        {hasMore && (
                            <div className="flex items-center justify-center py-2 text-gray-500 dark:text-gray-400">
                                <div className="w-5 h-5 border-t-2 border-blue-500 rounded-full animate-spin mr-2"></div>
                                Loading messages...
                            </div>
                        )}
                        {messages.map((message, index) => (
                            <div 
                                key={index}
                                ref={index === messages.length - 1 ? scrollToBottom : undefined}
                                className={`flex ${message.id === currentUser?.id ? 'justify-end' : 'justify-start'}`}
                            >
                                <div className={`flex max-w-[70%] ${message.id === currentUser?.id ? 'flex-row-reverse' : 'flex-row'}`}>
                                    {message.id !== currentUser?.id && (
                                        <Avatar 
                                            imgUrl={message.avatar} 
                                            containerClassName="w-8 h-8 rounded-full flex-shrink-0 mx-2"
                                        />
                                    )}
                                    <div className={`flex flex-col ${message.id === currentUser?.id ? 'items-end' : 'items-start'}`}>
                                        {message.id !== currentUser?.id && (
                                            <span className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                                                {message.displayName}
                                            </span>
                                        )}
                                        <div className={`rounded-2xl px-4 py-2 ${
                                            message.id === currentUser?.id 
                                                ? 'bg-blue-500 text-white' 
                                                : 'bg-gray-100 dark:bg-neutral-800 text-gray-900 dark:text-white'
                                        }`}>
                                            {message.text}
                                        </div>
                                        <div className="flex items-center mt-1 space-x-2">
                                            <span className="text-xs text-gray-500 dark:text-gray-400">
                                                {moment(message.timestamp?.seconds * 1000).format('LT')}
                                            </span>
                                            {message.id === currentUser?.id && (
                                                <i className="las la-check-double text-blue-500"></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="border-t border-gray-200 dark:border-neutral-700 p-4 bg-white dark:bg-neutral-900">
                    <form 
                        onSubmit={handleSendMessage}
                        className="flex items-center space-x-2"
                    >
                        <button 
                            type="button"
                            className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full transition-colors"
                        >
                            <i className="las la-plus text-xl"></i>
                        </button>
                        <div className="flex-1 flex items-center bg-gray-100 dark:bg-neutral-800 rounded-full px-4">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Send a message"
                                className="flex-1 py-2 bg-transparent focus:outline-none text-gray-900 dark:text-white"
                            />
                            <div className="flex items-center space-x-2">
                                <button 
                                    type="button"
                                    className="p-2 hover:bg-gray-200 dark:hover:bg-neutral-700 rounded-full transition-colors"
                                >
                                    <i className="las la-smile text-xl"></i>
                                </button>
                                <button 
                                    type="button"
                                    className="p-2 hover:bg-gray-200 dark:hover:bg-neutral-700 rounded-full transition-colors"
                                >
                                    <i className="las la-paperclip text-xl"></i>
                                </button>
                            </div>
                        </div>
                        <button 
                            type="submit"
                            disabled={!newMessage.trim()}
                            className={`p-2 rounded-full ${
                                newMessage.trim() 
                                    ? 'bg-blue-500 hover:bg-blue-600 text-white' 
                                    : 'bg-gray-200 dark:bg-neutral-800 text-gray-400'
                            } transition-colors`}
                        >
                            <i className="las la-paper-plane text-xl"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default observer(CommunityGroupChat);
