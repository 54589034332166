import { PROFESSIONAL_GLOBAL_LOCALS_BASE_URL, ProfessionalProfileDto, fetchGlobalLikeMindedLocals } from 'api/professionalLocals';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Heading from 'shared/Heading/Heading';
import { Loader } from 'shared/Loader/Loader';
import NcImage from 'shared/NcImage/NcImage';
import PlaceholderSmallH from '../../images/placeholder-small.png';

const ITEMS_PER_PAGE = 12;

const GlobalProfessionalsPage: React.FC = () => {
  const navigate = useNavigate();
  const [professionals, setProfessionals] = useState<ProfessionalProfileDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchProfessionals = async (pageNum: number) => {
    try {
      setIsLoading(true);
      const response = await fetchGlobalLikeMindedLocals(
        PROFESSIONAL_GLOBAL_LOCALS_BASE_URL,
        { page: pageNum, limit: ITEMS_PER_PAGE }
      );
      const result = response.data;
      
      if (pageNum === 1) {
        setProfessionals(result.items);
      } else {
        setProfessionals(prev => [...prev, ...result.items]);
      }
      
      setHasMore(result.items.length > 0);
    } catch (error) {
      console.error('Error fetching global professionals:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfessionals(1);
  }, []);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchProfessionals(nextPage);
  };

  const handleCardClick = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Heading
        className="mb-8 text-neutral-900 dark:text-neutral-50"
        desc="Connect with professionals from around the world"
      >
        Global Professionals
      </Heading>

      {isLoading && professionals.length === 0 ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {professionals.map((professional) => (
              <div
                key={professional.id}
                className="relative bg-white dark:bg-neutral-900 rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow cursor-pointer"
                onClick={() => handleCardClick(professional.id)}
              >
                <div className="aspect-w-16 aspect-h-9">
                  <NcImage
                    src={professional.bgImage || PlaceholderSmallH}
                    alt=""
                    className="object-cover w-full h-32"
                  />
                </div>
                <div className="p-4">
                  <div className="flex items-center space-x-4">
                    <Avatar
                      imgUrl={professional.avatar || ''}
                      userName={professional.displayName}
                      sizeClass="w-16 h-16"
                    />
                    <div>
                      <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                        {professional.displayName}
                      </h3>
                      <p className="text-sm text-neutral-500 dark:text-neutral-400">
                        {professional.role}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="flex flex-wrap gap-2 mb-3">
                      <Badge name={professional.industry} />
                      {professional.companyName && (
                        <Badge name={professional.companyName} />
                      )}
                    </div>
                    <p className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-2">
                      {professional.desc}
                    </p>
                  </div>
                  {professional.matchScore && (
                    <div className="mt-4 flex items-center">
                      <span className="text-sm font-medium text-primary-600">
                        {Math.round(professional.matchScore)}% Match
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {hasMore && (
            <div className="mt-10 flex justify-center">
              <ButtonPrimary onClick={handleLoadMore} loading={isLoading}>
                Load More Results
              </ButtonPrimary>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GlobalProfessionalsPage; 