// ... (other imports)
import { Link } from 'react-router-dom';
import Avatar from "shared/Avatar/Avatar";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { NotificationType } from "api/notifications";
import { INotification } from "api/notifications";
import { convertUTCToLocal } from "utils/dates";
import Eye from '../../images/eye.svg';

// Define the NotificationMessage interface
interface NotificationMessage {
  text: string;
  imageUrl: string;
  time: string;
  subtitle?: string;
  redirectUrl?: string;
}

// Abstract builder class
abstract class NotificationMessageBuilder {
  protected notification: INotification;

  constructor(notification: INotification) {
    this.notification = notification;
  }

  abstract buildMessage(): NotificationMessage;

  protected convertTime(): string {
    return convertUTCToLocal(this.notification.data.time);
  }
}

// Concrete builder classes for different notification types
class ConnectionRequestMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { senderId, senderName, imageUrl } = this.notification.data;

    return {
      text: `${senderName} is interested to connect with you.`,
      imageUrl,
      time: this.convertTime(),
      redirectUrl: `/user/${senderId}`,
    };
  }
}

class MatchFoundMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { text, imageUrl, subtitle } = this.notification.data;
    return {
      text: `Match Found: ${text}`,
      imageUrl,
      time: this.convertTime(),
      subtitle: `Matched with: ${subtitle}`,
    };
  }
}

class NewCommunityMemberMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { communityName, newMemberImageUrl, newMemberName, newMemberId } = this.notification.data;
    
    return {
      text: `New user <strong>${newMemberName}</strong> joined the community <strong>${communityName}</strong>`,
      imageUrl: newMemberImageUrl,
      time: this.convertTime(),
      subtitle: `Welcome: ${newMemberName}`,
      redirectUrl: `/user/${newMemberId}`,
    };
  }
}

class DefaultMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { text, imageUrl, subtitle } = this.notification.data;
    return {
      text,
      imageUrl,
      time: this.convertTime(),
      subtitle,
    };
  }
}

class ProfileViewMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { industry, role } = this.notification.data;
    return {
      text: `Someone from ${industry} working as ${role} viewed your profile`,
      time: this.convertTime(),
      imageUrl: Eye,
      redirectUrl: '/profile-views',
    };
  }
}

// Function to build the notification message based on its type
export const buildNotificationMessage = (notification: INotification): NotificationMessage => {
  let builder: NotificationMessageBuilder;

  switch (notification.type) {
    case NotificationType.CONNECTION_REQUEST:
      builder = new ConnectionRequestMessageBuilder(notification);
      break;
    case NotificationType.MATCH_FOUND:
      builder = new MatchFoundMessageBuilder(notification);
      break;
    case NotificationType.NEW_COMMUNITY_MEMBER:
      builder = new NewCommunityMemberMessageBuilder(notification);
      break;
    case NotificationType.PROFILE_VIEW:
      builder = new ProfileViewMessageBuilder(notification);
      break;
    default:
      builder = new DefaultMessageBuilder(notification);
  }

  return builder.buildMessage();
};
