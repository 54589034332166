import { fetchBulletinsByCommunity } from "api/bulletins";
import { FC, useEffect, useState } from "react";
import DigitalBulletinBoardCard from "./DigitalBulletinBoardCard";
import DigitalBulletinHeading from "./DigitalBulletinHeading";
import { BulletinResponse } from "interface/communities";
import { MegaphoneIcon } from "@heroicons/react/24/outline";

export interface DigitalBulletinBoardProps {
  className?: string;
  communityId: string;
}

const BULLETINS_TO_SHOW = 4;

const DigitalBulletinBoard: FC<DigitalBulletinBoardProps> = ({
  className = "",
  communityId,
}) => {
  const [bulletins, setBulletins] = useState<BulletinResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleNewBulletin = (newBulletin: BulletinResponse) => {
    setBulletins(prevBulletins => [newBulletin, ...prevBulletins]);
  };

  useEffect(() => {
    const fetchBulletins = async () => {
      if (!communityId) return;
      try {
        setLoading(true);
        const response = await fetchBulletinsByCommunity(communityId);
        setBulletins(response.data);
      } catch (err: any) {
        console.error("Error fetching bulletins:", err.message || err);
      } finally {
        setLoading(false);
      }
    };

    fetchBulletins();
  }, [communityId]);

  return (
    <div className={`flow-root ${className}`}>
      <DigitalBulletinHeading
        communityId={communityId}
        onBulletinCreated={handleNewBulletin}
      />
      
      <div className="mt-6">
        {loading ? (
          <div className="space-y-6">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="animate-pulse">
                <div className="flex items-center space-x-4">
                  <div className="rounded-full bg-gray-100 h-12 w-12"></div>
                  <div className="flex-1 space-y-2">
                    <div className="h-4 bg-gray-100 rounded w-3/4"></div>
                    <div className="h-3 bg-gray-100 rounded w-1/2"></div>
                  </div>
                </div>
                <div className="mt-4 space-y-2">
                  <div className="h-3 bg-gray-100 rounded w-full"></div>
                  <div className="h-3 bg-gray-100 rounded w-5/6"></div>
                </div>
              </div>
            ))}
          </div>
        ) : bulletins.length > 0 ? (
          <div className="divide-y divide-gray-50">
            {bulletins.slice(0, BULLETINS_TO_SHOW).map((bulletin) => (
              <DigitalBulletinBoardCard
                key={bulletin.id}
                bulletin={bulletin}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 px-4">
            <div className="p-3 bg-gray-50 rounded-full inline-flex">
              <MegaphoneIcon className="h-6 w-6 text-gray-400" />
            </div>
            <h3 className="mt-4 text-sm font-medium text-gray-900">No announcements</h3>
            <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
              Share updates, news, and important information with your community members.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DigitalBulletinBoard;
