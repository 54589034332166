import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import CardActivityCreator from "components/ActivityCreator/CardActivityCreator";
import Heading from "components/Heading/Heading";
import NoResultCard from "components/NoResult/GenericNoResultCard";
import { ActivityResponse } from "interface/activity";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionGridActivityBoxProps {
  className?: string;
  activities?: ActivityResponse[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
  heading?: string;
  desc?: string;
  communityId?: string;
}

const SectionGridActivityBox: FC<SectionGridActivityBoxProps> = ({
  className = "",
  activities = [],
  boxCard = "box1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
  heading = " ",
  desc = "",
  communityId = ""
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`nc-SectionGridAuthorBox relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading className="activities-section mb-10 lg:mb-16 text-neutral-900 dark:text-neutral-50" desc={desc} isCenter>{heading}</Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {activities.length === 0 ? (
          <div className="col-span-full">
            <NoResultCard displayName="✨ Be the first to create an activity!" />
          </div>
        ) :
          activities.map((activity, index) =>
            boxCard === "box2" ? (
              <CardActivityCreator key={activity.id} activity={activity}/>
            ) : null
          )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        {activities.length >= 10 && (
          <ButtonSecondary>Show me more</ButtonSecondary>
        )}
        <ButtonPrimary onClick={() => navigate('/activity/create?community_id=' + communityId)}>Create an Activity</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridActivityBox;
