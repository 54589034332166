import { Dialog } from '@headlessui/react';
import googleSvg from "images/Google.svg";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClimbingBoxLoader } from "react-spinners";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { authStore } from "stores/AuthStore";
import { PageType, navigationStore } from 'stores/NavigationStore';

const PageLogin = () => {
  const [email, setEmail] = useState('');
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const loginFormRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    navigationStore.setCurrentPage(PageType.LOGIN);
  }, []);

  useEffect(() => {
    const checkLoginFormVisibility = () => {
      if (loginFormRef.current) {
        const rect = loginFormRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight;
        setShowScrollButton(!isVisible);
      }
    };

    checkLoginFormVisibility();
    window.addEventListener('scroll', checkLoginFormVisibility);
    window.addEventListener('resize', checkLoginFormVisibility);

    return () => {
      window.removeEventListener('scroll', checkLoginFormVisibility);
      window.removeEventListener('resize', checkLoginFormVisibility);
    };
  }, []);

  useEffect(() => {
    if (authStore?.currentUser?.profile) {
      const params = new URLSearchParams(location.search);
      const communityId = params.get('community_id');
      
      if (communityId) {
        navigate(`/community/${communityId}`);
        return;
      }

      navigate(navigationStore.getRedirectPath());
    }
  }, [authStore?.currentUser, location.search]);

  const scrollToLoginForm = () => {
    loginFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleGoogleSignIn = async () => {
    authStore.setLoading(true);
    try {
      await authStore.googleSignIn();
    } catch (error) {
      console.log(error);
      toast.error('Google sign in failed. Please try again.');
    } finally {
      authStore.setLoading(false);
    }
  }

  const handleLinkedInSignIn = async () => {
    // Implement LinkedIn sign in
    toast.info('LinkedIn sign in coming soon!');
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await authStore.sendSignInLinkToEmailHandler(email);
      localStorage.setItem('emailForSignIn', email);
      setShowEmailConfirmation(true);
      setEmail('');
      toast.success('Verification email has been sent!');
    } catch (error) {
      toast.error('Failed to send verification email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (authStore.loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-purple-600 to-purple-800">
        <ClimbingBoxLoader color="#ffffff" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-purple-800">
      <Helmet>
        <title>Login | MeetWith</title>
      </Helmet>

      {showScrollButton && (
        <button
          onClick={scrollToLoginForm}
          className="fixed bottom-8 right-8 bg-white rounded-full p-4 shadow-lg z-50 animate-bounce"
          aria-label="Scroll to login form"
        >
          <svg
            className="w-6 h-6 text-purple-600"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
          </svg>
        </button>
      )}

      <div className="container mx-auto px-4 py-8 flex flex-col lg:flex-row items-center justify-between">
        {/* Left side - Welcome content */}
        <div className="lg:w-1/2 text-white mb-12 lg:mb-0">
          <div className="max-w-xl">
            <h1 className="text-3xl md:text-5xl font-bold mb-6">
              Welcome to MeetWith – Your Business Networking Hub
            </h1>
            <p className="text-base md:text-xl mb-8">
              Connect with professionals, share experiences, and maximize every business trip. Joining MeetWith is simple and rewarding!
            </p>

            <div className="space-y-8">
              <div className="flex items-start space-x-4">
                <div className="bg-white/10 p-4 rounded-lg">
                  <span className="text-lg font-semibold">01</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Create Your Profile</h3>
                  <p className="text-white/80">Add your industry, role, and interests to start finding meaningful connections.</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <div className="bg-white/10 p-4 rounded-lg">
                  <span className="text-lg font-semibold">02</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Plan Your Trips</h3>
                  <p className="text-white/80">Share your travel details to discover like-minded professionals at your destination.</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <div className="bg-white/10 p-4 rounded-lg">
                  <span className="text-lg font-semibold">03</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Connect & Collaborate</h3>
                  <p className="text-white/80">Join activities, engage in communities, and expand your professional network effortlessly.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side - Login form */}
        <div ref={loginFormRef} className="lg:w-5/12">
          <div className="bg-white rounded-2xl shadow-xl p-8">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900">Sign In</h2>
              <p className="text-gray-600 mt-2">Use your work email or social accounts</p>
            </div>

            <div className="space-y-4">
              <button
                onClick={handleGoogleSignIn}
                className="w-full flex justify-center items-center gap-3 px-4 py-3 border border-gray-300 
                rounded-lg shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 
                transition-colors duration-200"
              >
                <img src={googleSvg} alt="Google" className="w-5 h-5" />
                <span>Continue with Google</span>
              </button>


              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with email</span>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Work Email
                  </label>
                  <div className="mt-1">
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="name@company.com"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 
                      rounded-lg shadow-sm placeholder-gray-400 
                      focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                      required
                    />
                  </div>
                </div>

                <ButtonPrimary
                  loading={isLoading}
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg 
                  shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 
                  transition-colors duration-200"
                >
                  Get Magic Link
                </ButtonPrimary>
              </form>

              <p className="mt-4 text-sm text-center text-gray-600">
                Don't have an account?{' '}
                <Link to="/register" className="text-purple-600 hover:underline font-medium">
                  Sign up
                </Link>
              </p>

              <p className="mt-4 text-xs text-center text-gray-500">
                By continuing, you agree to our{' '}
                <a href="https://valuable-crate-b4c.notion.site/Terms-And-Conditions-1099e55f776d8093b007fdd60f4c3b85?pvs=73" 
                   target="_blank" 
                   className="text-purple-600 hover:underline">
                  Terms of Service
                </a>
                {' '}and{' '}
                <a href="https://valuable-crate-b4c.notion.site/Privacy-Policy-1099e55f776d802e95c1e373377c4472" 
                   target="_blank" 
                   className="text-purple-600 hover:underline">
                  Privacy Policy
                </a>.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Email Confirmation Dialog */}
      <Dialog
        open={showEmailConfirmation}
        onClose={() => setShowEmailConfirmation(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6 shadow-xl">
            <Dialog.Title className="text-lg font-medium text-gray-900 mb-2">
              Check your email
            </Dialog.Title>
            
            <Dialog.Description className="text-sm text-gray-500 mb-4">
              We've sent a magic link to your email address. 
              Click the link in the email to sign in to your account.
            </Dialog.Description>

            <div className="mt-4 space-y-3">
              <button
                onClick={() => setShowEmailConfirmation(false)}
                className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 
                  rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default observer(PageLogin);
