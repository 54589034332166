import React from 'react';
import { CommunityData } from 'interface/communities';
import { UsersIcon, ChatBubbleLeftRightIcon, CalendarIcon } from '@heroicons/react/24/outline';

interface HeroSectionProps {
  communityData: CommunityData | null;
}

const HeroSection: React.FC<HeroSectionProps> = ({ communityData }) => {
  const stats = [
    {
      icon: <UsersIcon className="w-5 h-5" />,
      value: communityData?.communityUsers?.length || 0,
      label: 'Members',
      color: 'bg-blue-50 text-blue-600'
    },
    // {
    //   icon: <ChatBubbleLeftRightIcon className="w-5 h-5" />,
    //   value: '24', // Replace with actual chat count
    //   label: 'Active Chats',
    //   color: 'bg-green-50 text-green-600'
    // },
    // {
    //   icon: <CalendarIcon className="w-5 h-5" />,
    //   value: '12', // Replace with actual activities count
    //   label: 'Activities',
    //   color: 'bg-purple-50 text-purple-600'
    // }
  ];

  return (
    <div className="relative overflow-hidden bg-white border-b border-gray-200">
      {/* Background Pattern */}
      <div 
        className="absolute inset-0 opacity-5"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      />

      {/* Content */}
      <div className="relative px-4 py-8 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
          {/* Community Logo */}
          <div className="flex-shrink-0">
            <img
              src={communityData?.logo}
              alt={communityData?.name}
              className="w-32 h-32 object-fit rounded-lg shadow-md border border-gray-200"
            />
          </div>

          {/* Community Info */}
          <div className="flex-grow">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900">
                  {communityData?.name}
                </h1>
                <div className="flex gap-2">
                  {communityData?.website && (
                    <a 
                      href={communityData.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-2 rounded-full bg-gray-50 text-gray-600 hover:bg-gray-100 transition-colors"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                      </svg>
                    </a>
                  )}
                  {communityData?.linkedin && (
                    <a 
                      href={communityData.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-2 rounded-full bg-blue-50 text-blue-600 hover:bg-blue-100 transition-colors"
                    >
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                      </svg>
                    </a>
                  )}
                </div>
              </div>
              <p className="text-gray-600 text-sm md:text-base line-clamp-2">
                {communityData?.description}
              </p>
              
              {/* Stats */}
              <div className="flex flex-wrap gap-6 mt-4">
                {stats.map((stat, index) => (
                  <div 
                    key={index}
                    className="flex items-center gap-2"
                  >
                    <div className={`p-2 rounded-full ${stat.color}`}>
                      {stat.icon}
                    </div>
                    <div>
                      <div className="text-xl font-semibold text-gray-900">
                        {stat.value}
                      </div>
                      <div className="text-sm text-gray-600">
                        {stat.label}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection; 