import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { CommunityUser } from "interface/communities";
import { observer } from "mobx-react";
import { authStore } from "stores/AuthStore";

export interface CommunityUserBoxProps {
  className?: string;
  communityUser: CommunityUser;
}

const CommunityUserBox: FC<CommunityUserBoxProps> = ({
  className = "",
  communityUser,
}) => {
  const { user, profile } = communityUser;
  const isMe = authStore.currentUser?.id === user.id;

  return (
    <Link
      to={`${isMe ? '' : `/user/${user.id}`}`}
      className={`nc-CardAuthorBox flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow border border-gray-300 dark:border-neutral-700 ${className}`}
      data-nc-id="CardAuthorBox"
    >
      <div className="relative flex-shrink-0">
        <div>
          <NcImage
            containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
            src={profile.bgImg}
          />
        </div>
        {/* Industry Tag */}
        {profile.industry && (
          <div className="absolute top-3 inset-x-3 flex">
            <div className="py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium truncate max-w-[180px]">
              {profile.industry}
            </div>
          </div>
        )}
      </div>

      <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
        <svg
          className="h-12 text-white dark:text-neutral-900 dark:group-hover:text-neutral-800"
          viewBox="0 0 135 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M101.911 19.8581C99.4421 17.4194 97.15 14.8065 94.6816 12.1935C94.3289 11.671 93.8 11.3226 93.271 10.8C92.9184 10.4516 92.7421 10.2774 92.3895 9.92903C85.8658 3.83226 76.8737 0 67.1763 0C57.4789 0 48.4868 3.83226 41.7868 9.92903C41.4342 10.2774 41.2579 10.4516 40.9053 10.8C40.3763 11.3226 40.0237 11.671 39.4947 12.1935C37.0263 14.8065 34.7342 17.4194 32.2658 19.8581C23.45 28.7419 11.6368 30.4839 0 30.8323V54H16.5737H32.2658H101.734H110.374H134.176V30.6581C122.539 30.3097 110.726 28.7419 101.911 19.8581Z"
            fill="currentColor"
          />
        </svg>
        <span className="absolute top-2">
          <Avatar
            containerClassName=""
            sizeClass="w-12 h-12 text-2xl"
            radius="rounded-full"
            imgUrl={profile.avatar}
            userName={profile.fullName}
          />
        </span>

        <div className="mt-6 w-full">
          {/* Name */}
          <h2 className="text-base font-medium">
            <span className="line-clamp-1">{profile.fullName}</span>
          </h2>

          {/* Role & Company */}
          {(profile.role || profile.companyName) && (
            <div className="mt-1.5 space-y-1">
              {profile.role && (
                <span className="block text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {profile.role}
                </span>
              )}
              {profile.companyName && (
                <span className="block text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {profile.companyName}
                </span>
              )}
            </div>
          )}

          {/* Bio Preview */}
          {/* {profile?.bio && (
            <p className="mt-2 text-xs text-neutral-500 dark:text-neutral-400 line-clamp-2">
              {profile.bio}
            </p>
          )} */}

          {/* Languages */}
          {/* {profile.languages && profile.languages.length > 0 && (
            <div className="mt-2 flex flex-wrap gap-1 justify-center">
              {profile.languages.slice(0, 2).map((lang, index) => (
                <span
                  key={index}
                  className="inline-block px-2 py-0.5 text-xs bg-neutral-100 dark:bg-neutral-800 rounded-full"
                >
                  {lang}
                </span>
              ))}
              {profile.languages.length > 2 && (
                <span className="inline-block px-2 py-0.5 text-xs bg-neutral-100 dark:bg-neutral-800 rounded-full">
                  +{profile.languages.length - 2}
                </span>
              )}
            </div>
          )} */}
        </div>
      </div>
    </Link>
  );
};

export default observer(CommunityUserBox);