import { fetchLikeMindedLocals, PROFESSIONAL_LOCALS_BASE_URL, ProfessionalProfileDto } from 'api/professionalLocals';
import { MATCHING_TRAVELERS_BASE_URL } from 'api/trip';
import FooterNav from 'components/FooterNav';
import Heading from 'components/Heading/Heading';
import { PaginationLinks, PaginationResult } from 'interface/pagination';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useParams } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import BadgeScore from 'shared/BadgeScore/BadgeScore';
import convertNumbThousand from 'utils/convertNumbThousand';

export interface ProfessionalLocalsPageProps {
  locals?: ProfessionalProfileDto[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}
const ProfessionalLocalsPage: React.FC<ProfessionalLocalsPageProps> = ({
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-20",
}) => {
  const { tripId } = useParams();
  const [locals, setProfessionalLocals] = useState<ProfessionalProfileDto[]>([]);
  const [paginationLinks, setPaginationLinks] = useState<PaginationLinks | null>(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (!tripId || tripId == '1') {
      fetchData(PROFESSIONAL_LOCALS_BASE_URL);
    } else {
      fetchData(`${MATCHING_TRAVELERS_BASE_URL}${tripId}`);
    }
  }, []);

  const fetchData = async (url: string) => {
    try {
      const response = await fetchLikeMindedLocals(url);
      const data: PaginationResult<ProfessionalProfileDto[]> = response.data;
      setProfessionalLocals((prevVcs) => [...prevVcs, ...data.items]);
      setPaginationLinks(data.links);
      setHasMore(!!data.links.next);
    } catch (error) {
      console.error('Failed to fetch VC firms:', error);
    }
  };

  const fetchMoreVcFirms = () => {
    if (paginationLinks && paginationLinks.next) {
      fetchLikeMindedLocals(paginationLinks.next);
    }
  };

  return (
    <div className="flex flex-col justify-center w-full h-full px-4 sm:px-6 lg:px-8 py-4 lg:py-12 bg-neutral-50 dark:bg-neutral-900">
      <Heading>
        <h2 className="text-4xl font-semibold">Meet Locals</h2>
      </Heading>
      <InfiniteScroll
        dataLength={locals.length}
        next={fetchMoreVcFirms}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p style={{ textAlign: 'center' }}><b>No data to show</b></p>}
      >
        <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
          {locals.map((local, i) => (
            <Link
              key={i}
              to={`/user/${local.id}`}
              className={`
                nc-CardOfProfessional relative flex flex-col p-5 sm:p-6 
                bg-white dark:bg-neutral-800 
                rounded-xl shadow-sm
                transition-all duration-300 ease-in-out
                hover:shadow-lg hover:scale-[1.02]
                hover:bg-neutral-50 dark:hover:bg-neutral-700
              `}
              data-nc-id="CardOfProfessional"
            >
              <BadgeScore
                className="absolute right-4 top-4"
                color="gray"
                name=''
                matchScore={local?.matchScore}
              />

              <div className="flex items-center mb-4">
                <div className="relative flex-shrink-0 w-16 h-16 rounded-full overflow-hidden ring-2 ring-primary-500 ring-offset-2">
                  <Avatar
                    imgUrl={local.avatar ?? ''}
                    userName={local.displayName}
                    sizeClass="h-16 w-16 text-xl"
                    containerClassName="flex-shrink-0"
                    radius="rounded-full"
                  />
                </div>
                <div className="ml-4 flex-grow overflow-hidden">
                  <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100 mb-1">
                    <span className="line-clamp-1">{local.displayName}</span>
                  </h2>
                  {local.companyName && (
                    <span className="inline-block px-3 py-1 text-sm bg-primary-50 dark:bg-primary-900 text-primary-700 dark:text-primary-300 rounded-full">
                      {local.companyName}
                    </span>
                  )}
                </div>
              </div>

              <div className="space-y-2 mt-2">
                {local.industry && (
                  <div className="flex items-center text-neutral-600 dark:text-neutral-300">
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <span className="text-sm">{local.industry}</span>
                  </div>
                )}
                {local.role && (
                  <div className="flex items-center text-neutral-600 dark:text-neutral-300">
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <span className="text-sm">{local.role}</span>
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      <FooterNav />
    </div>
  );
};

export default ProfessionalLocalsPage;
