import FooterNav from 'components/FooterNav';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { authStore } from 'stores/AuthStore';
import { firestore } from '../../firebase';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import Avatar from 'shared/Avatar/Avatar';

interface Conversation {
    id: string;
    lastMessage: string;
    sentAt: string;
    participants: string[];
    participantsMetaData: {
        [key: string]: {
            avatar: string;
            fullName: string
        };
    }
    status: {
        [key: string]: 'seen' | 'not-seen';
    };
}

const ConversationSkeleton = () => (
    <div className="animate-pulse flex items-center gap-4 p-4 rounded-lg">
        <div className="w-12 h-12 rounded-full bg-gray-200"></div>
        <div className="flex-1 space-y-2">
            <div className="h-3 bg-gray-200 rounded w-1/4"></div>
            <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
    </div>
);

const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4">
        <ChatBubbleLeftRightIcon className="w-16 h-16 text-gray-300 mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-1">No messages yet</h3>
        <p className="text-sm text-gray-500 text-center">
            Connect with professionals to start conversations
        </p>
    </div>
);

function PageMessages() {
    const navigate = useNavigate();
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadConversations = async () => {
            if (!authStore.currentUser?.id) return;
            try {
                const userConversations = await fetchUserConversations(authStore.currentUser.id);
                setConversations(userConversations);
            } finally {
                setIsLoading(false);
            }
        };

        loadConversations();
    }, []);

    const fetchUserConversations = async (userId: string): Promise<Conversation[]> => {
        const q = query(
            collection(firestore, 'conversations'),
            where('participants', 'array-contains', userId)
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            lastMessage: doc.data().lastMessage,
            sentAt: doc.data().sentAt,
            participants: doc.data().participants,
            participantsMetaData: doc.data().participantsMetaData,
            status: doc.data().status
        }));
    };

    const getReceiverInfo = (conversation: Conversation) => {
        if (!conversation?.participantsMetaData) return { name: '', avatar: '', id: '' };
        
        const [receiverId] = Object.keys(conversation.participantsMetaData)
            .filter(id => id !== authStore.currentUser?.id);

        return {
            name: conversation.participantsMetaData[receiverId]?.fullName || '',
            avatar: conversation.participantsMetaData[receiverId]?.avatar || '',
            id: receiverId
        };
    };

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <div className="bg-white border-b border-gray-200">
                <div className="max-w-2xl mx-auto px-4 py-4">
                    <h1 className="text-xl font-semibold text-gray-900">Messages</h1>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-2xl mx-auto px-4 py-4 mb-16">
                {isLoading ? (
                    <div className="space-y-3">
                        <ConversationSkeleton />
                        <ConversationSkeleton />
                        <ConversationSkeleton />
                    </div>
                ) : conversations.length === 0 ? (
                    <EmptyState />
                ) : (
                    <div className="space-y-2">
                        {conversations.map((conversation) => {
                            const receiver = getReceiverInfo(conversation);
                            const isUnread = conversation.status[authStore.currentUser?.id || ''] === 'not-seen';

                            return (
                                <button
                                    key={conversation.id}
                                    onClick={() => navigate(`/chat/${receiver.id}`)}
                                    className="w-full flex items-center gap-4 p-4 rounded-lg hover:bg-white 
                                    transition-colors duration-200 group relative"
                                >
                                    {/* Avatar */}
                                    <div className="relative flex-shrink-0">
                                        <Avatar
                                            imgUrl={receiver.avatar || ''}
                                            userName={receiver.name || ''}
                                            sizeClass="w-12 h-12 rounded-full object-cover"
                                        />
                                        {isUnread && (
                                            <span className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full" />
                                        )}
                                    </div>

                                    {/* Content */}
                                    <div className="flex-1 min-w-0">
                                        <div className="flex flex-col">
                                            <div className="flex justify-between items-baseline">
                                                <h3 className={`text-sm font-medium truncate ${isUnread ? 'text-gray-900' : 'text-gray-700'}`}>
                                                    {receiver.name}
                                                </h3>
                                                <span className="text-xs text-gray-500">
                                                    {moment(conversation.sentAt).fromNow()}
                                                </span>
                                            </div>
                                            <p className={`text-sm truncate mt-1 text-left ${isUnread ? 'text-gray-900' : 'text-gray-500'}`}>
                                                {conversation.lastMessage}
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            );
                        })}
                    </div>
                )}
            </div>

            {/* Footer */}
            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
                <FooterNav />
            </div>
        </div>
    );
}

export default observer(PageMessages);