import React from 'react';
import { CommunityData } from 'interface/communities';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import Avatar from 'shared/Avatar/Avatar';

interface RecentMembersFeedProps {
  communityData: CommunityData;
  maxMembers?: number;
}

const RecentMembersFeed: React.FC<RecentMembersFeedProps> = ({
  communityData,
  maxMembers = 3
}) => {
  const navigate = useNavigate();

  const recentMembers = React.useMemo(() => {
    if (!communityData?.communityUsers) return [];
    
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    
    return [...communityData.communityUsers]
      .filter(member => new Date(member.joinedAt) >= oneWeekAgo)
      .sort((a, b) => new Date(b.joinedAt).getTime() - new Date(a.joinedAt).getTime())
      .slice(0, maxMembers);
  }, [communityData, maxMembers]);

  const handleViewProfile = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  const handleViewAllMembers = () => {
    navigate(`/community/members/${communityData.id}/page`);
  };

  if (recentMembers.length === 0) {
    return (
      <div className="text-center py-8">
        <div className="p-3 bg-gray-50 rounded-full inline-flex">
          <svg className="w-6 h-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg>
        </div>
        <h3 className="mt-4 text-sm font-medium text-gray-900">No members yet</h3>
        <p className="mt-2 text-sm text-gray-500">Be the first to join this community.</p>
      </div>
    );
  }

  return (
    <div className="space-y-1">
      {recentMembers.map((member) => (
        <div
          key={member.id}
          onClick={() => handleViewProfile(member.user.id)}
          className="group relative flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg transition-all cursor-pointer"
        >
          {/* Avatar with Status */}
          <div className="relative flex-shrink-0">
            <Avatar
              imgUrl={member?.profile?.avatar || ''}
              userName={member?.profile?.fullName || ''}
              sizeClass="w-10 h-10 rounded-full object-cover ring-2 ring-white"
            />
            <span className="absolute -bottom-0.5 -right-0.5 block h-3 w-3 rounded-full ring-2 ring-white bg-green-400" />
          </div>

          {/* Member Info */}
          <div className="min-w-0 flex-1">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900 truncate group-hover:text-blue-600 transition-colors">
                {member.profile?.fullName || 'Anonymous'}
              </p>
              <span className="inline-flex items-center rounded-full px-2 py-0.5 text-[10px] font-medium bg-gray-50 text-gray-600">
                {formatDistanceToNow(new Date(member?.joinedAt), { addSuffix: true })}
              </span>
            </div>
            <div className="flex items-center gap-2 mt-0.5">
              {member?.profile?.role && (
                <span className="text-xs text-gray-500 truncate">
                  {member?.profile?.role}
                </span>
              )}
              {member?.profile?.companyName && (
                <>
                  <span className="text-gray-300">•</span>
                  <span className="text-xs text-gray-500 truncate">
                    {member?.profile?.companyName}
                  </span>
                </>
              )}
            </div>
          </div>

          {/* View Profile Icon */}
          <div className="opacity-0 group-hover:opacity-100 transition-opacity">
            <ArrowTopRightOnSquareIcon className="w-4 h-4 text-gray-400" />
          </div>
        </div>
      ))}

      { communityData?.communityUsers?.length && communityData?.communityUsers?.length > maxMembers && (
        <button
          onClick={handleViewAllMembers}
          className="w-full mt-4 py-3 flex items-center justify-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900 transition-colors border-t border-gray-100"
        >
          View all members
          <ArrowTopRightOnSquareIcon className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default RecentMembersFeed; 