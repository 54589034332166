import { Dialog, Transition } from "@headlessui/react";
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  CameraIcon,
  EyeIcon,
  GlobeAltIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { getMetadata } from "api/metadata";
import FooterNav from "components/FooterNav";
import Label from "components/Label/Label";
import { Languages } from "data/languages";
import { UpdateProfileDto } from "interface/user.interface";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import { authStore } from "stores/AuthStore";
import { onboardingStore } from "stores/OnboardingStore";
import CommonLayout from "./CommonLayout";
import EditProfileModal from './EditProfileModal';
import ImageCropperModal from './ImageCropperModal';
import BgImageCropperModal from './BgImageCropperModal';
import { getProfileViews } from "api/profile";
import { useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

export interface AccountPageProps {
  className?: string;
}

interface FormErrors {
  fullName?: string;
  phone?: string;
  email?: string;
  birthDate?: string;
  industry?: string;
  role?: string;
  bio?: string;
}


const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { currentUser } = authStore;
  const [metadata, setMetdata] = useState<{
    industries: { value: string; label: string }[];
    roles: { value: string; label: string }[];
  }>({
    industries: [],
    roles: [],
  });
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [isOpenEditProfile, setisOpenEditProfile] = useState(false);
  const navigate = useNavigate();

  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const [formFields, setFormFields] = useState<UpdateProfileDto>({
    id: currentUser?.id || "",
    fullName: currentUser?.profile.fullName,
    companyName: currentUser?.profile.companyName || "",
    industry: currentUser?.profile.industry,
    role: currentUser?.profile.role,
    placeDescription: currentUser?.profile.placeDescription,
    interests: currentUser?.profile.interests,
    avatar: currentUser?.profile.avatar,
    birthDate: currentUser?.profile.birthDate,
    phone: currentUser?.profile.phone || "",
    languages: currentUser?.profile?.languages || [],
    bio: currentUser?.profile.bio || "",
    bgImg: currentUser?.profile.bgImg || "",
    linkedinUrl: currentUser?.profile.linkedinUrl || "",
    facebookUrl: currentUser?.profile.facebookUrl || "",
    instagramUrl: currentUser?.profile.instagramUrl || "",
    twitterUrl: currentUser?.profile.twitterUrl || "",
  });

  // State to handle delete account popup visibility
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});

  // Add this state
  const [isLoading, setIsLoading] = useState(false);

  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);

  const [isBgCropperOpen, setIsBgCropperOpen] = useState(false);
  const [bgImageToCrop, setBgImageToCrop] = useState<string | null>(null);

  const [profileViews, setProfileViews] = useState<{ views: any[]; totalCount: number }>({ views: [], totalCount: 0 });

  useEffect(() => {
    getMetadata().then((res) => {
      const { data } = res;
      setMetdata(data);
    });

    // Fetch profile views
    const fetchProfileViews = async () => {
      try {
        const views = await getProfileViews();
        setProfileViews(views);
      } catch (error) {
        console.error('Error fetching profile views:', error);
      }
    };

    fetchProfileViews();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    // Required fields validation
    if (!formFields.fullName?.trim()) {
      newErrors.fullName = 'Username is required';
    }

    if (!formFields.role) {
      newErrors.role = 'Role is required';
    }

    if (!formFields.industry) {
      newErrors.industry = 'Industry is required';
    }

    // Birth date validation
    if (formFields.birthDate) {
      const birthDate = new Date(formFields.birthDate);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();

      if (age < 13) {
        newErrors.birthDate = 'You must be at least 13 years old';
      }

      if (birthDate > today) {
        newErrors.birthDate = 'Birth date cannot be in the future';
      }
    }

    // Display errors if any
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // Display the first error message
      const firstError = Object.values(newErrors)[0];
      toast.error(firstError, { theme: "light" });
      return false;
    }

    setErrors({});
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      await authStore.updateProfile(formFields);
      toast.success("Profile updated successfully", { theme: "light" });
    } catch (error) {
      toast.error("An error occurred. Please try again", { theme: "light" });
    } finally {
      setIsLoading(false);
      setisOpenEditProfile(false);
    }
  };

  const handleLocationResult = (locationDetails: google.maps.GeocoderResult) => {
    const { formatted_address } = locationDetails;
    const placeDescription = formatted_address;
    setFormFields((prev) => ({
      ...prev,
      placeDescription,
      locationDetails,
    }));
  };

  const handleAvatarChange = (file: File) => {
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size should be less than 1MB", { theme: "light" });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageToCrop(reader.result as string);
      setIsCropperOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCroppedImage = (croppedImage: Blob) => {
    const formData = new FormData();
    formData.append("file", croppedImage);
    authStore
      .updateAvatar(formData)
      .then((res) => {
        toast.success("Avatar updated successfully", { theme: "light" })
        setFormFields((prev) => ({
          ...prev,
          avatar: res,
        }));
      })
      .catch(() => toast.error("An error occurred. Please try again", { theme: "light" }));
  };

  const handleBgImageChange = (file: File) => {
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size should be less than 1MB", { theme: "light" });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setBgImageToCrop(reader.result as string);
      setIsBgCropperOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCroppedBgImage = (croppedImage: Blob) => {
    const formData = new FormData();
    formData.append("file", croppedImage);
    authStore
      .updateBackgroundImage(formData)
      .then((res) => {
        toast.success("Background image updated successfully", { theme: "light" });
        setFormFields((prev) => ({
          ...prev,
          bgImg: res,
        }));
      })
      .catch(() => toast.error("An error occurred. Please try again", { theme: "light" }));
  };

  const handleChanges = (key: string, newRole: string) => {
    setFormFields((prev) => ({
      ...prev,
      [key]: newRole,
    }));
  };

  // Function to delete account
  const handleDeleteAccount = () => {
    authStore
      .deleteAccount()
      .then(() => {
        toast.success("Account deleted successfully", { theme: "light" });
        // Handle any necessary redirects or state updates after account deletion
      })
      .catch(() => toast.error("An error occurred while deleting the account", { theme: "light" }));
    setShowDeletePopup(false);
  };

  const handleLanguagesChanges = (shouldAdd: boolean, langName: string) => {
    if (shouldAdd) {
      // Add the language if it's not already present
      if (!formFields?.languages?.includes(langName)) {
        formFields?.languages?.push(langName);
      }
    } else {
      // Remove the language if it's present
      const index = formFields?.languages?.indexOf(langName);
      if (index && index !== -1) {
        formFields?.languages?.splice(index, 1);
      }
    }
    setFormFields((prev) => ({
      ...prev,
      languages: formFields?.languages,
    }));
  }

  const renderMoreFilterItem = (
    data: {
      name: string;
      nativeName?: string;
      defaultChecked?: boolean;
    }[]
  ) => {

    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-y-7 gap-x-4 sm:gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.nativeName}
              label={item.name}
              defaultChecked={formFields?.languages?.includes(item.name)}
              onChange={(checked) => handleLanguagesChanges(checked, item.name)}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.nativeName}
              label={item.name}
              defaultChecked={formFields?.languages?.includes(item.name)}
              onChange={(checked) => handleLanguagesChanges(checked, item.name)}
            />
          ))}
        </div>
      </div>
    );
  };


  const renderChooseLang = () => {
    return (
      <div className="">
        {<Label>Language</Label>}

        <div
          className={`flex items-center justify-center px-4 py-3 sm:px-6 text-sm rounded-full border text-neutral-700 dark:text-neutral-200 cursor-pointer`}
          onClick={openModalMoreFilter}
        >

          <span>Choose Your Languages</span>
          {/* {renderXClear()} */}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium ">
                          Choose Your Languages
                        </h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(Languages)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        onboardingStore.clearLanguages()
                        closeModalMoreFilter()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <CommonLayout>
        {/* Cover Image Section */}
        <div className="relative max-h-80 mb-8">
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={formFields?.bgImg || "/api/placeholder/1200/300"}
        />
          <button className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg">
            <CameraIcon className="w-5 h-5 text-gray-600" />

            <input
              type="file"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={(e) => {
                if (e.target.files?.[0]) handleBgImageChange(e.target.files[0]);
              }}
            />
          </button>
        </div>

        <div className="space-y-6 sm:space-y-8">
          {/* Profile Header */}
          <div className="flex justify-between items-start">
            <div className="flex items-center gap-6">
              <div className="relative">
                <Avatar sizeClass="w-32 h-32" imgUrl={formFields?.avatar} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer rounded-full">
                  <CameraIcon className="w-6 h-6" />
                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e) => {
                    if (e.target.files?.[0]) handleAvatarChange(e.target.files[0]);
                  }}
                />
              </div>
              <div>
                <h2 className="text-3xl font-semibold">{formFields?.fullName}</h2>
                <div className="flex items-center gap-2 text-gray-600 mt-2">
                  <BriefcaseIcon className="w-4 h-4" />
                  <span>{formFields?.role || "Add your role"}</span>
                </div>
              </div>
            </div>
            <ButtonPrimary onClick={() => setisOpenEditProfile(true)} className="flex items-center gap-2 sm:text-base text-sm">
              <PencilIcon className="w-4 h-4 sm:block hidden" />
              <span className="sm:inline hidden">Edit Profile</span>
              <span className="sm:hidden inline">Edit</span>
            </ButtonPrimary>
          </div>

          {/* Analytics Cards */}
          <div className="grid grid-cols-2 gap-4">
            <div 
              className="bg-white rounded-lg shadow-sm border border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800 cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => navigate('/profile-views')}
            >
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <EyeIcon className="w-5 h-5 text-blue-500" />
                  <h3 className="text-sm font-medium">Profile Views</h3>
                  <p className="ml-auto text-lg font-semibold text-blue-600">{profileViews.totalCount}</p>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-sm border border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <UserIcon className="w-5 h-5 text-purple-500" />
                  <h3 className="text-sm font-medium">AI Matches</h3>
                  {/* <p className="ml-auto text-lg font-semibold text-purple-600">0</p> */}
                  <span className="text-xs font-medium bg-purple-100 text-purple-800 rounded-full px-2">Soon</span>
                </div>
              </div>
            </div>
          </div>

          {/* Profile Views List */}
          {/* {profileViews.views.length > 0 && (
            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4">Recent Profile Viewers</h3>
              <div className="space-y-4">
                {profileViews.views.map((view: any) => (
                  <div key={view.id} className="flex items-center gap-4 p-4 bg-white rounded-lg shadow-sm border border-neutral-200">
                    <Avatar sizeClass="w-12 h-12" imgUrl={view.viewer.avatar} />
                    <div>
                      <h4 className="font-medium">{view.viewer.fullName}</h4>
                      <p className="text-sm text-gray-500">
                        Viewed {new Date(view.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )} */}

          {/* Profile Details */}
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div className="flex items-center gap-2 text-gray-600">
                <BuildingOfficeIcon className="w-5 h-5" />
                <span>{formFields?.companyName || "Add your company name"}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <BriefcaseIcon className="w-5 h-5" />
                <span>{formFields?.industry}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <MapPinIcon className="w-5 h-5" />
                <span>{formFields?.placeDescription}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <GlobeAltIcon className="w-5 h-5" />
                <span>{formFields?.languages?.join(", ")}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <PhoneIcon className="w-5 h-5" />
                <span>{formFields?.phone || "Add your phone number"}</span>
              </div>
            </div>

            {/* Social Media Links */}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Social Media</h3>
              <div className="flex gap-4">
                {formFields?.linkedinUrl && (
                  <a 
                    href={formFields.linkedinUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="w-10 h-10 flex items-center justify-center rounded-full bg-[#0077b5] text-white hover:bg-opacity-90 transition-all"
                    title="LinkedIn Profile"
                  >
                    <i className="lab la-linkedin text-2xl"></i>
                  </a>
                )}
                {formFields?.facebookUrl && (
                  <a 
                    href={formFields.facebookUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="w-10 h-10 flex items-center justify-center rounded-full bg-[#1877f2] text-white hover:bg-opacity-90 transition-all"
                    title="Facebook Profile"
                  >
                    <i className="lab la-facebook text-2xl"></i>
                  </a>
                )}
                {formFields?.instagramUrl && (
                  <a 
                    href={formFields.instagramUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-[#405DE6] via-[#E1306C] to-[#FFDC80] text-white hover:bg-opacity-90 transition-all"
                    title="Instagram Profile"
                  >
                    <i className="lab la-instagram text-2xl"></i>
                  </a>
                )}
                {formFields?.twitterUrl && (
                  <a 
                    href={formFields.twitterUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="w-10 h-10 flex items-center justify-center rounded-full bg-[#1da1f2] text-white hover:bg-opacity-90 transition-all"
                    title="Twitter Profile"
                  >
                    <i className="lab la-twitter text-2xl"></i>
                  </a>
                )}
              </div>
            </div>

            {/* Bio Section */}
            <div>
              <h3 className="text-xl font-semibold mb-2">About</h3>
              {formFields?.bio ? (
                <p className="text-gray-600">{formFields.bio}</p>
              ) : (
                <p className="text-gray-500 italic">Tell others about yourself! Add a bio to help people get to know you better.</p>
              )}
            </div>

            {/* Add Delete Account Section */}
            <div className="border-t border-neutral-200 dark:border-neutral-700 pt-6 mt-6">
              <h3 className="text-xl font-semibold text-red-600 mb-2">Danger Zone</h3>
              <p className="text-gray-600 mb-4">Once you delete your account, there is no going back. Please be certain.</p>
              <button
                onClick={() => setShowDeletePopup(true)}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors duration-150"
              >
                Delete Account
              </button>
            </div>
          </div>

          {/* Keep existing edit modal logic */}
          {/* ... existing Dialog component ... */}
          <EditProfileModal
            isOpen={isOpenEditProfile}
            onClose={() => setisOpenEditProfile(false)}
            formFields={formFields}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleChanges={handleChanges}
            handleLocationResult={handleLocationResult}
            isLoading={isLoading}
            metadata={metadata}
            errors={errors}
          />
        </div>
      </CommonLayout>
      <FooterNav />

      {/* Modal using Headless UI */}
      <Dialog
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        className="fixed z-50 inset-0 flex items-center justify-center"
      >
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
        <div className="bg-white p-6 rounded-lg max-w-md w-full z-10">
          <Dialog.Title className="text-xl font-bold">
            Confirm Account Deletion
          </Dialog.Title>
          <Dialog.Description className="mt-2">
            Are you sure you want to delete your account? This action cannot be undone.
          </Dialog.Description>
          <div className="mt-4 flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-300 rounded-md"
              onClick={() => setShowDeletePopup(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded-md"
              onClick={handleDeleteAccount}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>

      <ImageCropperModal
        isOpen={isCropperOpen}
        onClose={() => setIsCropperOpen(false)}
        onCrop={handleCroppedImage}
        imageSrc={imageToCrop || ''}
      />

      <BgImageCropperModal
        isOpen={isBgCropperOpen}
        onClose={() => setIsBgCropperOpen(false)}
        onCrop={handleCroppedBgImage}
        imageSrc={bgImageToCrop || ''}
      />
    </div>
  );
};

export default observer(AccountPage);
