import { useState } from 'react';
import FooterNav from 'components/FooterNav';
import CommonLayout from 'containers/AccountPage/CommonLayout';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';
import { goals } from 'stores/OnboardingStore';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { toast } from 'react-toastify';

function PageMyGoals({ className = "" }) {
    const { currentUser } = authStore;

    // Local state to manage goals
    const [selectedGoals, setSelectedGoals] = useState<string[]>(currentUser?.profile?.goals ?? []);
    const [loading, setLoading] = useState<boolean>(false);

    const toggleGoal = (goalName: string) => {
        setSelectedGoals((prevGoals) =>
            prevGoals.includes(goalName)
                ? prevGoals.filter((id) => id !== goalName)
                : [...prevGoals, goalName]
        );
    };

    const saveGoals = async () => {
        if (currentUser?.id) {
            setLoading(true);
            try {
                await authStore.updateProfile({ id: currentUser.id, goals: selectedGoals });
                toast.success('Goals updated successfully', {
                    theme: "light"
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <CommonLayout>
            <div className="space-y-6 sm:space-y-8">
                <div className="max-w-3xl mx-auto">
                    <h2 className="text-3xl font-semibold text-center mb-4">Set up your goals</h2>
                    <p className="text-center text-neutral-500 dark:text-neutral-400 mb-8">
                        Choose your goals to help us understand your priorities.
                        This information allows our AI to suggest the most relevant connections and opportunities for you.
                    </p>

                    <div className={`nc-WidgetTags w-full max-w-3xl mx-auto px-4 sm:px-6 ${className}`}>
                        <div className="bg-white dark:bg-neutral-900 rounded-2xl shadow-lg p-5 sm:p-8">
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-4">
                                {goals.map((goal) => (
                                    <button
                                        key={goal.name}
                                        onClick={() => toggleGoal(goal.name)}
                                        className={`
                                            group relative flex flex-col items-center justify-center
                                            aspect-square p-3 sm:p-4
                                            rounded-xl transition-all duration-200
                                            hover:scale-105 hover:shadow-md
                                            ${selectedGoals.includes(goal.name)
                                                ? 'bg-primary-50 dark:bg-primary-900 border-2 border-primary-500 shadow-md'
                                                : 'bg-neutral-50 dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700'
                                            }
                                        `}
                                    >
                                        <span className="text-2xl sm:text-3xl mb-2 transition-transform group-hover:scale-110">
                                            {goal.icon}
                                        </span>
                                        <span className="text-center text-xs sm:text-sm font-medium text-neutral-700 dark:text-neutral-200">
                                            {goal.name}
                                        </span>
                                        {selectedGoals.includes(goal.name) && (
                                            <span className="absolute top-2 right-2 text-primary-500">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </span>
                                        )}
                                    </button>
                                ))}
                            </div>

                            {/* Save Button */}
                            <div className="mt-8 text-center">
                                <ButtonPrimary
                                    loading={loading}
                                    className="min-w-[200px]"
                                    onClick={saveGoals}
                                >
                                    Update Goals
                                </ButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterNav />
            </div>
        </CommonLayout>
    );
}

export default observer(PageMyGoals);
