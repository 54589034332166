import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Lottie from 'react-lottie';
import { GameCenter } from '../CommunityDetailPage/GameCenter/GameCenter';
import gameAnimation from '../../animations/game-loading.json';

const CommunityGamesPage: React.FC = observer(() => {
  const { communityId } = useParams<{ communityId: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time to show the animation
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="w-64 h-64">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: gameAnimation,
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Community Games</h1>
          {communityId && <GameCenter communityId={communityId} />}
        </div>
      </div>
    </div>
  );
});

export default CommunityGamesPage; 