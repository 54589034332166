import { GameType } from '../interface/games';
import axiosInstance from 'utils/setAuthorizationHeader';

export const startGame = async (communityId: string, type: GameType) => {
  const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/games/community/${communityId}/start`, { type });
  return response.data;
};

export const getActiveGame = async (communityId: string) => {
  const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/games/community/${communityId}/active`);
  return response.data;
};

export const joinGame = async (gameId: string) => {
  const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/games/${gameId}/join`);
  return response.data;
};

export const submitMove = async (gameId: string, moveData: any) => {
  const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/games/${gameId}/move`, moveData);
  return response.data;
};

export const getLeaderboard = async (gameId: string) => {
  const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/games/${gameId}/leaderboard`);
  return response.data;
}; 