import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import FooterNav from "components/FooterNav";
import { useHomeData } from "./hooks/useHomeData";
import { ExploreSection } from "./components/ExploreSection";
import HeaderFilter from "./HeaderFilter";
import { notificationStore } from "stores/NotificationsStore";
import { tripStore } from "stores/TripStore";
import { EventsSection } from "./components/EventsSection";
import { TripSection } from "./components/TripSection";
import { CommunitiesSection } from "./components/CommunitiesSection";
import { ActivitiesSection } from "./components/ActivitiesSection";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { getIntroStatus, markIntroAsCompleted } from "api/profile";
import { GlobalSection } from "./components/GlobalSection";

const INTRO_NAME = "exploration_page_intro";
const INTRO_STORAGE_KEY = "exploration_page_intro_completed";

function PageHome() {
  const {
    activeTab,
    currentTrip,
    data,
    setActiveTabHandler,
    isLoading
  } = useHomeData();

  const checkIntroStatus = async () => {
    try {
      // First check localStorage
      const localStorageStatus = localStorage.getItem(INTRO_STORAGE_KEY);
      if (localStorageStatus === "true") {
        return true;
      }

      
      // If not in localStorage, check with backend
      const response = await getIntroStatus(INTRO_NAME);
      if (response) {
        localStorage.setItem(INTRO_STORAGE_KEY, 'true');
      }
      return response;
    } catch (error) {
      console.error("Error checking intro status:", error);
      return false;
    }
  };

  const markIntroAsCompletedFunction = async () => {
    try {
      await markIntroAsCompleted(INTRO_NAME);
      localStorage.setItem(INTRO_STORAGE_KEY, 'true');
    } catch (error) {
      console.error('Error marking intro as completed:', error);
    }
  };

  const startIntroTour = () => {
    setTimeout(() => {
    introJs()
      .setOptions({
        steps: [
          {
            element: ".header-filter",
            intro: `
              <div style="text-align: center;">
                <h3 style="color: #4a90e2; margin-bottom: 10px;">🌎 Explore and Add Trip Section ✈️</h3>
                <p style="font-size: 14px; color: #333;">
                  Welcome to the Explore and Add Trip Page! This is your hub for networking, discovery, and planning. Use the "Add Trip" button to organize your upcoming trips, connect with travelers and locals, and unlock personalized opportunities at your destination.
                </p>
              </div>
            `,
          },
          {
            element: ".explore-section",
            intro: `
              <div style="text-align: left;">
                <h3 style="color: #4a90e2; margin-bottom: 10px;">🌟 Discover Opportunities Around You</h3>
                <p style="font-size: 14px; color: #333;">
                  <ul style="margin: 5px 0; padding-left: 20px; list-style-type: disc;">
                    <li><strong>🤝 Connect Locally:</strong> Find and network with professionals in your area</li>
                    <li><strong>✈️ Discover Travelers:</strong> Meet people visiting your location</li>
                  </ul>
                </p>
              </div>
            `,
          },
          {
            element: ".communities-section",
            intro: `
              <div style="text-align: left;">
                <h3 style="color: #4a90e2; margin-bottom: 10px;">👥 Communities</h3>
                <p style="font-size: 14px; color: #333;">
                  Engage with communities of like-minded people, share ideas, and grow together.
                </p>
              </div>
            `,
          },
          {
            element: ".activities-section",
            intro: `
              <div style="text-align: left;">
                <h3 style="color: #4a90e2; margin-bottom: 10px;">🎯 Activities Section</h3>
                <p style="font-size: 14px; color: #333;">
                  Join events, meetups, or fun gatherings near you or at your destination.
                </p>
              </div>
            `,
          },
        ],
        showProgress: true,
        showBullets: true,
        exitOnOverlayClick: true,
        disableInteraction: false,
      })
      .oncomplete(() => {
        markIntroAsCompletedFunction();
      })
      .onexit(() => {
        markIntroAsCompletedFunction();
      })
      .start();
    }, 1000);
  };

  useEffect(() => {
    const initializePage = async () => {
      const introCompleted = await checkIntroStatus();
      if (!introCompleted) {
        startIntroTour();
      }
    };

    initializePage();
  }, []);

  useEffect(() => {
    tripStore.fetchMyTrips();
    notificationStore.checkForUnreadMessages();
  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Home Page</title>
      </Helmet>

      <div className="container relative space-y-5 mb-24 lg:space-y-5 lg:mb-28">
        {/* Header Filter */}
        <div className="relative header-filter">
          <HeaderFilter
            tabActive={activeTab}
            tabs={tripStore.myTrips}
            onClickTab={(tab, trip) => setActiveTabHandler(tab, trip)}
          />
        </div>

        {/* Events Section */}
        <div className="events-section">
          <EventsSection currentTripId={currentTrip?.id} />
        </div>
        
        {/* Main Content */}
        {activeTab === 'Global' ? (
          <div className="global-section">
            <GlobalSection
              professionalLocals={data.professionalLocals}
              globalTravelers={tripStore.globalTravelers}
              isLoading={isLoading}
            />
          </div>
        ) : activeTab === 'Explore' ? (
          <div className="trip-section">
            <ExploreSection
              professionalLocals={data.professionalLocals}
              arrivalsNextMonth={tripStore.paginatedArrivalsNextMonth}
              isLoading={isLoading}
            />
          </div>
        ) : activeTab === 'My Trips' ? (
          <div className="trip-section">
            <TripSection
              currentTrip={currentTrip}
              localsAtDestination={tripStore.localsAtDestination}
              matchingTravelers={tripStore.matchingTravelers}
              isLoading={isLoading}
            />
          </div>
        ) : null}

        {/* Communities Section */}
        <div className="communities-section">
          <CommunitiesSection communities={data.communities} />
        </div>

        {/* Activities Section */}
        <div>
          <ActivitiesSection activities={data.activities} />
        </div>
      </div>
      
      {/* Footer Navigation */}
      <div className="footer-nav">
        <FooterNav />
      </div>
    </div>
  );
}

export default observer(PageHome);
