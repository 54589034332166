import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { fetchBulletinById } from "api/bulletins";
import { BulletinResponse } from "interface/communities";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BlueLoader from "shared/Loader/BlueLoader";
import Textarea from "shared/Textarea/Textarea";

const SingleBulletinPage = () => {
  const navigate = useNavigate();
  const { bulletin_id } = useParams();
  const [bulletin, setBulletin] = useState<BulletinResponse | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBulletin = async () => {
      if (!bulletin_id) return;

      try {
        setLoading(true);
        const response = await fetchBulletinById(bulletin_id);
        setBulletin(response.data);
      } catch (error) {
        console.error("Error fetching bulletin:", error);
        // Optionally redirect to 404 or show error message
      } finally {
        setLoading(false);
      }
    };

    fetchBulletin();
  }, [bulletin_id]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <BlueLoader />
    );
  }

  if (!bulletin) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <button
          onClick={handleBack}
          className="flex items-center text-neutral-600 dark:text-neutral-300 hover:text-neutral-800 dark:hover:text-neutral-100 mt-8 mb-4"
        >
          <ChevronLeftIcon className="h-5 w-5 mr-2" />
          Back
        </button>
        <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">
          Bulletin not found
        </h2>
        <button
          onClick={handleBack}
          className="mt-4 text-primary-600 hover:text-primary-500 font-medium"
        >
          Go back
        </button>
      </div>
    );
  }
  console.log(bulletin);

  return (
    <div className="nc-PageSingle">
      <Helmet>
        <title>Meet With || {bulletin.title}</title>
      </Helmet>

      <div className="nc-SingleContent container space-y-10">
        <div className="max-w-screen-md mx-auto">
          <button
            onClick={handleBack}
            className="flex items-center text-neutral-600 dark:text-neutral-300 hover:text-neutral-800 dark:hover:text-neutral-100 mt-8 mb-4"
          >
            <ChevronLeftIcon className="h-5 w-5 mr-2" />
            Back
          </button>

          {/* Header */}
          <div className="space-y-8">
            <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700 py-6">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <div className="flex items-center space-x-5">
                  <Avatar
                    imgUrl={bulletin.user?.profile?.avatar}
                    userName={bulletin.user?.profile?.fullName}
                    sizeClass="w-12 h-12"
                    radius="rounded-full"
                  />
                  <div className="space-y-1">
                    <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100 hover:text-primary-600 dark:hover:text-primary-500 transition">
                      {bulletin.user?.profile?.fullName}
                    </h3>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 flex items-center">
                      <span className="mr-2">Posted on</span>
                      {moment(bulletin.createdAt).format('LL')}
                    </span>
                  </div>
                </div>
                {/* <SocialsList className="mt-4 sm:mt-0" /> */}
              </div>
            </div>
          </div>
          <div className="mt-4 mb-6">
            <h1 className="text-xl font-semibold text-neutral-900 dark:text-neutral-100">
              {bulletin.title}
            </h1>
            {/* Content */}
            <div className="prose dark:prose-invert lg:prose-lg max-w-none">
              <p className="text-neutral-700 dark:text-neutral-300">
                {bulletin.content}
              </p>
            </div>
          </div>

          {/* Comments */}
          <div className="mt-10">
            <div className="flex items-center gap-3">
              <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                Comments
              </h3>
              <span className="px-3 py-1 text-xs font-medium bg-neutral-100 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-200 rounded-full">
                Coming soon
              </span>
            </div>
            <form className="mt-5">
              <Textarea placeholder="Write your response..." disabled />
              <div className="mt-3 space-x-3">
                <ButtonPrimary disabled>Submit</ButtonPrimary>
                <ButtonSecondary disabled>Cancel</ButtonSecondary>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBulletinPage;
