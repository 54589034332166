import React, { useRef, useState } from 'react';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { Dialog } from '@headlessui/react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface ImageCropperModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCrop: (croppedImage: Blob) => void;
  imageSrc: string;
}

const ImageCropperModal: React.FC<ImageCropperModalProps> = ({ isOpen, onClose, onCrop, imageSrc }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [cropper, setCropper] = useState<Cropper | null>(null);

  const handleCrop = () => {
    if (cropper) {
      cropper.getCroppedCanvas({
        width: 200,
        height: 200,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      }).toBlob((blob) => {
        if (blob) {
          onCrop(blob);
          onClose();
        }
      }, 'image/jpeg');
    }
  };

  const onImageLoad = () => {
    if (imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 1,
        viewMode: 1,
        dragMode: 'move',
        guides: false,
        center: true,
        highlight: false,
        background: false,
        autoCropArea: 1,
        responsive: true,
        cropBoxResizable: false,
        cropBoxMovable: false,
      });
      setCropper(cropperInstance);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed z-50 inset-0 flex items-center justify-center">
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
      <div className="bg-white p-6 rounded-lg max-w-md w-full z-10">
        <Dialog.Title className="text-xl font-bold">Crop Image</Dialog.Title>
        <div className="mt-4">
          <img ref={imageRef} src={imageSrc} alt="To be cropped" onLoad={onImageLoad} />
        </div>
        <div className="mt-4 flex justify-end space-x-4">
          <ButtonPrimary onClick={handleCrop}>Crop</ButtonPrimary>
          <button className="px-4 py-2 bg-gray-300 rounded-md" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </Dialog>
  );
};

export default ImageCropperModal; 