import { AxiosResponse } from 'axios';
import axiosInstance from 'utils/setAuthorizationHeader';
import { UserProfileDTO } from './dto/get-user-data-by-id.dto';
import { User } from 'interface/user.interface';

export async function getUser(): Promise<AxiosResponse<User>> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/authentication`, { withCredentials: true })
}

export async function getMemberDataById(userId: string | undefined): Promise<AxiosResponse<UserProfileDTO>>{
    if (!userId) {
        throw new Error('User id is required');
    }
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/users/${userId}`, { withCredentials: true })
}

export async function getUserByProfileId(id: string) {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/users/profile/${id}`, { withCredentials: true })
}

export async function getMemberDataByProfileId(id: number) {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/users/member-profile/${id}`, { withCredentials: true })
}

export async function searchContacts(url: string, data: any) {
    const baseURL = url || `${process.env.REACT_APP_API_URL}/users/contacts`
    return axiosInstance.post(baseURL, data, { withCredentials: true })
}

export async function deleteAccount() {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/users`, { withCredentials: true })
}