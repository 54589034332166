import { fetchBulletinsByCommunity } from "api/bulletins";
import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BulletinResponse } from "interface/communities";
import DigitalBulletinBoardCard from "../CommunityDetailPage/Widgets/DigitalBulletinBoard/DigitalBulletinBoardCard";
import DigitalBulletinHeading from "../CommunityDetailPage/Widgets/DigitalBulletinBoard/DigitalBulletinHeading";
import { ArrowLongLeftIcon, MegaphoneIcon } from "@heroicons/react/24/outline";

const BulletinBoardPage: FC = () => {
  const [bulletins, setBulletins] = useState<BulletinResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { communityId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBulletins = async () => {
      if (!communityId) return;
      try {
        setLoading(true);
        const response = await fetchBulletinsByCommunity(communityId);
        setBulletins(response.data);
      } catch (err: any) {
        console.error("Error fetching bulletins:", err.message || err);
      } finally {
        setLoading(false);
      }
    };

    fetchBulletins();
  }, [communityId]);

  const handleNewBulletin = (newBulletin: BulletinResponse) => {
    setBulletins(prevBulletins => [newBulletin, ...prevBulletins]);
  };

  const handleBack = () => {
    navigate(`/community/${communityId}`);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Back Navigation */}
        <button
          onClick={handleBack}
          className="group mb-8 inline-flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900 transition-colors"
        >
          <ArrowLongLeftIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-600 transition-colors" />
          <span>Back to Community</span>
        </button>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100">
          <div className="p-6">
            <DigitalBulletinHeading
              communityId={communityId || ""}
              onBulletinCreated={handleNewBulletin}
            />
          </div>

          <div className="border-t border-gray-100">
            {loading ? (
              <div className="divide-y divide-gray-50">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="p-6 animate-pulse">
                    <div className="flex items-center space-x-4">
                      <div className="rounded-full bg-gray-100 h-12 w-12"></div>
                      <div className="flex-1 space-y-2">
                        <div className="h-4 bg-gray-100 rounded w-3/4"></div>
                        <div className="h-3 bg-gray-100 rounded w-1/2"></div>
                      </div>
                    </div>
                    <div className="mt-4 space-y-2">
                      <div className="h-3 bg-gray-100 rounded w-full"></div>
                      <div className="h-3 bg-gray-100 rounded w-5/6"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : bulletins.length > 0 ? (
              <div className="divide-y divide-gray-50">
                {bulletins.map((bulletin) => (
                  <div key={bulletin.id} className="p-6">
                    <DigitalBulletinBoardCard bulletin={bulletin} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="py-16 px-4">
                <div className="text-center">
                  <div className="p-3 bg-gray-50 rounded-full inline-flex">
                    <MegaphoneIcon className="h-6 w-6 text-gray-400" />
                  </div>
                  <h3 className="mt-4 text-sm font-medium text-gray-900">No announcements</h3>
                  <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
                    Share updates, news, and important information with your community members.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulletinBoardPage; 