import { BulletinResponse } from "interface/communities";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';
import Avatar from "shared/Avatar/Avatar";

export interface DigitalBulletinBoardCardProps {
  bulletin: BulletinResponse;
}

const DigitalBulletinBoardCard: FC<DigitalBulletinBoardCardProps> = ({
  bulletin,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/bulletin/${bulletin.id}/page`);
  };

  return (
    <div
      onClick={handleClick}
      className="group relative bg-white hover:bg-gray-50 transition-colors duration-200 cursor-pointer"
    >
      <div className="flex items-start gap-4">
        {/* Avatar */}
        <div className="flex-shrink-0 mt-1">
          <Avatar
            imgUrl={bulletin.user?.profile?.avatar || ''}
            userName={bulletin.user?.profile?.fullName || ''}
            sizeClass="w-10 h-10 rounded-full object-cover ring-2 ring-white"
          />
        </div>

        {/* Content */}
        <div className="flex-1 min-w-0">
          {/* Header */}
          <div className="flex items-baseline justify-between gap-2">
            <div className="flex items-center gap-2 min-w-0">
              <h3 className="text-sm font-medium text-gray-900 truncate group-hover:text-blue-600 transition-colors">
                {bulletin.title}
              </h3>
            </div>
            <span className="inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium bg-gray-50 text-gray-600">
              {formatDistanceToNow(new Date(bulletin.createdAt), { addSuffix: true })}
            </span>
          </div>

          {/* Author */}
          <div className="mt-1 flex items-center gap-2 text-sm text-gray-500">
            <span className="font-medium truncate">
              {bulletin.user?.profile?.fullName}
            </span>
            {bulletin.user?.profile?.role && (
              <>
                <span className="text-gray-300">•</span>
                <span className="truncate">{bulletin.user.profile.role}</span>
              </>
            )}
          </div>

          {/* Content */}
          <div className="mt-2">
            <p className="text-sm text-gray-600 line-clamp-2 group-hover:text-gray-900 transition-colors">
              {bulletin.content}
            </p>
          </div>

          {/* Footer */}
          <div className="mt-3 flex items-center gap-4">
            <div className="flex items-center text-xs text-gray-500">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              Read more
            </div>
            {bulletin.user?.profile?.companyName && (
              <span className="text-xs text-gray-400">
                {bulletin.user.profile.companyName}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalBulletinBoardCard;
