import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import Autocomplete from "components/AutoComplete/AutoComplete";
import LocationAutocomplete from "components/Common/LocationAutocomplete";
import { UpdateProfileDto } from "interface/user.interface";
import moment from "moment-timezone";

interface EditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  formFields: UpdateProfileDto;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: () => void;
  handleChanges: (key: string, value: string) => void;
  handleLocationResult: (locationDetails: google.maps.GeocoderResult) => void;
  isLoading: boolean;
  metadata: {
    industries: { value: string; label: string }[];
    roles: { value: string; label: string }[];
  };
  errors: {
    fullName?: string;
    phone?: string;
    email?: string;
    birthDate?: string;
    industry?: string;
    role?: string;
    bio?: string;
  };
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  isOpen,
  onClose,
  formFields,
  handleInputChange,
  handleSubmit,
  handleChanges,
  handleLocationResult,
  isLoading,
  metadata,
  errors
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Edit Profile
              </Dialog.Title>

              <div className="mt-6 space-y-6">
                {/* Basic Information */}
                <div className="space-y-4">
                  <div>
                    <Label>Full Name</Label>
                    <Input
                      name="fullName"
                      value={formFields.fullName || ""}
                      onChange={handleInputChange}
                    />
                    {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
                  </div>

                  <div>
                    <Label>Company Name</Label>
                    <Input
                      name="companyName"
                      value={formFields.companyName}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <Label>Industry</Label>
                    <Autocomplete
                      defaultValue={formFields.industry || ""}
                      onChange={(value) => handleChanges("industry", value)}
                      options={metadata.industries.map(i => i.value)}
                    />
                    {errors.industry && <p className="text-red-500 text-sm">{errors.industry}</p>}
                  </div>

                  <div>
                    <Label>Role</Label>
                    <Autocomplete
                      defaultValue={formFields.role || ""}
                      onChange={(value) => handleChanges("role", value)}
                      options={metadata.roles.map(r => r.value)}
                    />
                    {errors.role && <p className="text-red-500 text-sm">{errors.role}</p>}
                  </div>

                  <div>
                    <Label>Location</Label>
                    <LocationAutocomplete
                      address={formFields.placeDescription || ""}
                      onLocationSelect={handleLocationResult}
                    />
                  </div>

                  <div>
                    <Label>Phone</Label>
                    <Input
                      name="phone"
                      value={formFields.phone}
                      onChange={handleInputChange}
                    />
                    {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                  </div>

                  <div>
                    <Label>Birth Date</Label>
                    <Input
                      type="date"
                      name="birthDate"
                      value={formFields.birthDate ? moment(formFields.birthDate).format('YYYY-MM-DD') : ""}
                      onChange={handleInputChange}
                    />
                    {errors.birthDate && <p className="text-red-500 text-sm">{errors.birthDate}</p>}
                  </div>

                  <div>
                    <Label>Bio</Label>
                    <Textarea
                      name="bio"
                      value={formFields.bio}
                      onChange={handleInputChange}
                      className="mt-1.5"
                      rows={4}
                    />
                    {errors.bio && <p className="text-red-500 text-sm">{errors.bio}</p>}
                  </div>

                  {/* Social Media Links */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-gray-900">Social Media Links</h4>
                    
                    <div>
                      <Label>LinkedIn URL</Label>
                      <div className="relative">
                        <i className="lab la-linkedin absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl"></i>
                        <Input
                          name="linkedinUrl"
                          value={formFields.linkedinUrl || ""}
                          onChange={handleInputChange}
                          placeholder="https://linkedin.com/in/your-profile"
                          className="pl-10"
                        />
                      </div>
                    </div>

                    <div>
                      <Label>Facebook URL</Label>
                      <div className="relative">
                        <i className="lab la-facebook absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl"></i>
                        <Input
                          name="facebookUrl"
                          value={formFields.facebookUrl || ""}
                          onChange={handleInputChange}
                          placeholder="https://facebook.com/your-profile"
                          className="pl-10"
                        />
                      </div>
                    </div>

                    <div>
                      <Label>Instagram URL</Label>
                      <div className="relative">
                        <i className="lab la-instagram absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl"></i>
                        <Input
                          name="instagramUrl"
                          value={formFields.instagramUrl || ""}
                          onChange={handleInputChange}
                          placeholder="https://instagram.com/your-profile"
                          className="pl-10"
                        />
                      </div>
                    </div>

                    <div>
                      <Label>Twitter URL</Label>
                      <div className="relative">
                        <i className="lab la-twitter absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl"></i>
                        <Input
                          name="twitterUrl"
                          value={formFields.twitterUrl || ""}
                          onChange={handleInputChange}
                          placeholder="https://twitter.com/your-profile"
                          className="pl-10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 flex justify-end space-x-4">
                <ButtonThird onClick={onClose} className="!bg-red-50">
                  Cancel
                </ButtonThird>
                <ButtonPrimary 
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  Save Changes
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditProfileModal;
