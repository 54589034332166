import {
    ArrowRightIcon,
    CalendarIcon,
    ChatBubbleLeftRightIcon,
    PuzzlePieceIcon,
    SparklesIcon,
    UserGroupIcon
} from '@heroicons/react/24/outline';
import { getAnalytics, logEvent } from "firebase/analytics";
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface QuickActionsProps {
    communityId: string;
}

const QuickActions: React.FC<QuickActionsProps> = ({ communityId }) => {
    const navigate = useNavigate();

    const trackFeatureClick = (featureName: string) => {
        const analytics = getAnalytics();
        logEvent(analytics, 'feature_click', {
            feature_name: featureName,
            page: 'community_detail'
        });
    };

    const actions = [
        {
            name: 'Chat Group',
            icon: <ChatBubbleLeftRightIcon className="w-6 h-6" />,
            onClick: () => {
                trackFeatureClick('chat_group');
                navigate(`/community/chat?community_id=${communityId}`);
            },
            gradient: 'from-green-50 to-emerald-50',
            iconColor: 'text-green-600',
            borderColor: 'border-green-100',
            hoverBg: 'hover:bg-green-50',
            description: 'Join community discussions',
            mobileIcon: (
                <div className="relative">
                    <ChatBubbleLeftRightIcon className="w-7 h-7" />
                    <span className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full border-2 border-white" />
                </div>
            )
        },
        {
            name: 'Members',
            icon: <UserGroupIcon className="w-6 h-6" />,
            onClick: () => {
                trackFeatureClick('community_members');
                navigate(`/community/members/${communityId}/page`);
            },
            gradient: 'from-purple-50 to-indigo-50',
            iconColor: 'text-purple-600',
            borderColor: 'border-purple-100',
            hoverBg: 'hover:bg-purple-50',
            description: 'View all community members',
            mobileIcon: (
                <div className="relative">
                    <UserGroupIcon className="w-7 h-7" />
                    <span className="absolute -bottom-1 -right-1 flex items-center justify-center w-4 h-4 bg-purple-100 rounded-full border-2 border-white">
                        <ArrowRightIcon className="w-2 h-2 text-purple-600" />
                    </span>
                </div>
            )
        },
        {
            name: 'AI Search',
            icon: (
                <div className="relative">
                    <SparklesIcon className="w-6 h-6" />
                </div>
            ),
            onClick: () => {
                trackFeatureClick('community_search');
                navigate(`/ai-community-search?community_id=${communityId}`);
            },
            gradient: 'from-pink-50 to-rose-50',
            iconColor: 'text-pink-600',
            borderColor: 'border-pink-100',
            hoverBg: 'hover:bg-pink-50',
            description: 'AI-powered member search',
            mobileIcon: (
                <div className="relative">
                    <SparklesIcon className="w-7 h-7" />
                </div>
            )
        },
        {
            name: 'Activities',
            icon: <CalendarIcon className="w-6 h-6" />,
            onClick: () => {
                trackFeatureClick('community_activities');
                navigate(`/community/activities/search?community_id=${communityId}`);
            },
            gradient: 'from-orange-50 to-red-50',
            iconColor: 'text-orange-600',
            borderColor: 'border-orange-100',
            hoverBg: 'hover:bg-orange-50',
            description: 'Explore community events',
            mobileIcon: (
                <div className="relative">
                    <CalendarIcon className="w-7 h-7" />
                    <span className="absolute -bottom-1 -right-1 w-4 h-4 bg-orange-100 rounded-full border-2 border-white flex items-center justify-center">
                        <span className="w-1.5 h-1.5 bg-orange-500 rounded-full"></span>
                    </span>
                </div>
            )
        },
        {
            name: 'Games',
            icon: <PuzzlePieceIcon className="w-6 h-6" />,
            gradient: 'from-blue-50 to-blue-50',
            iconColor: 'text-blue-600',
            borderColor: 'border-blue-100',
            hoverBg: 'hover:bg-blue-50',
            description: 'Play games with your community',
            onClick: () => {
                navigate(`/community/${communityId}/games`);
            },
            mobileIcon: (
                <div className="relative">
                    <PuzzlePieceIcon className="w-7 h-7" />
                </div>
            )
        }
    ];

    return (
        <div className="px-4 py-6 sm:px-6 lg:px-8">
            {/* Desktop View */}
            <div className="hidden md:grid grid-cols-5 gap-4">
                {actions.map((action, index) => (
                    <button
                        key={index}
                        onClick={action.onClick}
                        className={`group relative flex flex-col items-center justify-center p-6 rounded-xl 
              bg-gradient-to-br ${action.gradient} border ${action.borderColor} 
              transition-all duration-200 hover:shadow-lg ${action.hoverBg}`}
                    >
                        <div className={`p-4 rounded-full mb-3 ${action.iconColor} 
              group-hover:scale-110 transition-transform duration-200`}>
                            {action.icon}
                        </div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-1">
                            {action.name}
                        </h3>
                        <p className="text-sm text-gray-600 text-center">
                            {action.description}
                        </p>
                    </button>
                ))}
            </div>

            {/* Mobile View */}
            <div className="md:hidden grid grid-cols-2 gap-3">
                {actions.map((action, index) => (
                    <button
                        key={index}
                        onClick={action.onClick}
                        className={`relative flex items-center gap-3 p-4 rounded-xl ${action.gradient} 
              border ${action.borderColor} transition-all duration-200 ${action.hoverBg}
              active:scale-95 transform`}
                    >
                        <div className={`p-2 rounded-full ${action.iconColor}`}>
                            {action.mobileIcon}
                        </div>
                        <div className="text-left">
                            <h3 className="text-sm font-semibold text-gray-900">
                                {action.name}
                            </h3>
                        </div>
                        <ArrowRightIcon className="w-4 h-4 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2" />
                    </button>
                ))}
            </div>
        </div>
    );
};

export default QuickActions; 