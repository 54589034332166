import React, { useEffect, useState } from 'react';
import { GameParticipation } from '../../../interface/games';
import * as gamesApi from '../../../api/games';

interface GameLeaderboardProps {
  gameId: string;
}

const GameLeaderboard: React.FC<GameLeaderboardProps> = ({ gameId }) => {
  const [leaderboard, setLeaderboard] = useState<GameParticipation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const data = await gamesApi.getLeaderboard(gameId);
        setLeaderboard(data);
        setError(null);
      } catch (err) {
        setError('Failed to fetch leaderboard');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
    const interval = setInterval(fetchLeaderboard, 30000); // Refresh every 30 seconds

    return () => clearInterval(interval);
  }, [gameId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-40">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        {error}
      </div>
    );
  }

  return (
    <div>
      <h3 className="text-xl font-bold mb-4">Leaderboard</h3>
      <div className="bg-gray-50 rounded-lg overflow-hidden">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left text-gray-600">Rank</th>
              <th className="px-4 py-2 text-left text-gray-600">Player</th>
              <th className="px-4 py-2 text-right text-gray-600">Score</th>
              <th className="px-4 py-2 text-center text-gray-600">Status</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((participation, index) => (
              <tr
                key={participation.id}
                className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
              >
                <td className="px-4 py-2 text-gray-800">#{index + 1}</td>
                <td className="px-4 py-2">
                  <div className="flex items-center">
                    {participation.user.profile.avatar && (
                      <img
                        src={participation.user.profile.avatar}
                        alt={participation.user.profile.fullName}
                        className="w-6 h-6 rounded-full mr-2"
                      />
                    )}
                    <span className="text-gray-800">
                      {participation.user.profile.fullName}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-2 text-right font-semibold text-gray-800">
                  {participation.score}
                </td>
                <td className="px-4 py-2 text-center">
                  {participation.hasCompleted ? (
                    <span className="inline-block px-2 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-full">
                      Completed
                    </span>
                  ) : (
                    <span className="inline-block px-2 py-1 text-xs font-semibold text-yellow-800 bg-yellow-100 rounded-full">
                      In Progress
                    </span>
                  )}
                </td>
              </tr>
            ))}
            {leaderboard.length === 0 && (
              <tr>
                <td colSpan={4} className="px-4 py-8 text-center text-gray-500">
                  No players yet. Be the first to join!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GameLeaderboard; 