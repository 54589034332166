import { QrCodeIcon } from '@heroicons/react/24/outline';
import { HandshakeRequest, approveConnection, declineConnection, getHandshakes, handleQRScan } from 'api/connections';
import axios from 'axios';
import FooterNav from 'components/FooterNav';
import Heading from 'components/Heading/Heading';
import NoResults from 'components/NoResults/NoResults';
import QRScannerPage from 'components/QRScanner/QRScannerPage';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import BlueLoader from 'shared/Loader/BlueLoader';

const HandshakePage: React.FC = () => {
  const [requests, setRequests] = useState<HandshakeRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);
  const navigate = useNavigate();

  const fetchHandshakes = async () => {
    try {
      const data = await getHandshakes();
      setRequests(data);
    } catch (error) {
      toast.error('Failed to fetch handshake requests');
    } finally {
      setLoading(false);
    }
  };

  const handleRequest = async (requestId: string, action: 'accept' | 'reject') => {
    try {
      if (action === 'accept') {
        await approveConnection(requestId);
        toast.success(`Request ${action}ed successfully`, { theme: 'light' });
        navigate('/connections');
      } else {
        await declineConnection(requestId);
        toast.success(`Request ${action}ed successfully`, { theme: 'light' });
        fetchHandshakes();
      }
    } catch (error) {
      toast.error(`Failed to ${action} request`, { theme: 'light' });
    }
  };

  const handleQRScanFunction = async (qrData: string) => {
    try {
      await handleQRScan(qrData);
      toast.success('Connection request sent successfully');
      navigate('/connections');
    } catch (error) {
      toast.error('Failed to process QR code', { theme: 'light' });
    }
    setIsQRScannerOpen(false);
  };

  useEffect(() => {
    fetchHandshakes();
  }, []);

  if (loading) {
    return <BlueLoader />;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="flex justify-between items-center mb-6">
        <Heading desc="This is the handshake requests page">Handshake Requests</Heading>
        <ButtonPrimary
          onClick={() => setIsQRScannerOpen(true)}
          className="flex items-center gap-2 px-5 py-3 bg-gradient-to-r from-primary-600 to-primary-700 text-white font-semibold rounded-full shadow-lg hover:shadow-xl hover:from-primary-700 hover:to-primary-800 active:from-primary-800 active:to-primary-900 focus:outline-none focus:ring-4 focus:ring-primary-400 focus:ring-offset-2 transition-transform duration-200 transform hover:-translate-y-1 active:translate-y-0"
        >
          <QrCodeIcon className="h-6 w-6" aria-hidden="true" />
          <span>Scan QR</span>
        </ButtonPrimary>
      </div>

      {requests.length === 0 ? (
        <NoResults
          message="No pending connection requests"
          icon="🤝"
        />
      ) : (
        <div className="space-y-4">
          {requests.map((request) => (
            <div
              key={request.connectionId}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-200"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    {request.sender.avatar ? (
                      <img
                        src={request.sender.avatar}
                        alt={request.sender.name}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    ) : (
                      <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500 text-xl">
                          {request.sender.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">{request.sender.name}</h3>
                    <p className="text-sm text-gray-500">
                      Sent {new Date(request.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>

                {request.status === 'pending' ? (
                  <div className="flex space-x-3">
                    <button
                      onClick={() => handleRequest(request.connectionId, 'accept')}
                      className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => handleRequest(request.connectionId, 'reject')}
                      className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200"
                    >
                      Decline
                    </button>
                  </div>
                ) : (
                  <span className={`px-3 py-1 rounded-full text-sm ${request.status === 'accepted'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                    }`}>
                    {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <FooterNav />

      {isQRScannerOpen && <QRScannerPage
        isOpen={isQRScannerOpen}
        onClose={() => setIsQRScannerOpen(false)}
        onScan={handleQRScanFunction}
      />}
    </div>
  );
};

export default observer(HandshakePage); 