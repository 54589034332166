import axiosInstance from 'utils/setAuthorizationHeader';
// Interface for the axios response
export interface ConnectionResponse {
    id: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    sender: {
        id: string;
    };
    receiver: {
        id: string;
    };
}

// Interface for handshake requests
export interface HandshakeRequest {
    connectionId: string;
    status: 'pending' | 'accepted' | 'rejected';
    createdAt: string;
    updatedAt: string;
    sender: {
        id: string;
        name: string;
        avatar?: string;
    };
    receiver: {
        id: string;
        name: string;
        avatar?: string;
    };
}

// Interface for connections
export interface Connection {
    connectionId: string;
    user: {
        id: string;
        profile?: {
            fullName?: string;
            avatar?: string;
            role?: string;
            companyName?: string;
            industry?: string;
        }
    };
    createdAt: string;
    updatedAt: string;
}

export interface ConnectionRequest {
    id: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

// Function to get a connection
export async function getConnection(userId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/connection/${userId}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to get connection');
    }
}

// Function to get handshake requests
export async function getHandshakes(): Promise<HandshakeRequest[]> {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/connection/handshakes`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to get handshakes');
    }
}

// Function to approve a connection
export async function approveConnection(connectionId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/connection/${connectionId}/approve`, {}, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to approve connection');
    }
}

// Function to cancel a connection
export async function cancelConnection(connectionId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/connection/${connectionId}/cancel`, {}, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to cancel connection');
    }
}

// Function to decline a connection
export async function declineConnection(connectionId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/connection/${connectionId}/decline`, {}, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to decline connection');
    }
}

// Function to request a connection
export async function requestConnection(receiverId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/connection/request`, { receiverId }, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to request connection');
    }
}

// Function to get connections
export const getConnections = async (): Promise<Connection[]> => {
    try {
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_API_URL}/connection`, 
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch connections');
    }
};

export const generateQRCode = async (userId: string): Promise<{ qrData: string }> => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/connection/qr-code/${userId}`, { withCredentials: true });
    return response.data;
};

export const handleQRScan = async (qrData: string): Promise<ConnectionRequest> => {
    const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/connection/scan-qr`, { qrData }, { withCredentials: true });
    return response.data;
};
