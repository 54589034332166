import { User } from './user.interface';

export enum GameType {
  WORDLE = 'WORDLE',
  GUESS_WHO = 'GUESS_WHO'
}

export enum GameStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED'
}

export interface Game {
  id: string;
  type: GameType;
  status: GameStatus;
  gameData: any;
  startTime: string;
  endTime: string;
  participations: GameParticipation[];
}

export interface GameParticipation {
  id: string;
  user: User;
  score: number;
  participationData: any;
  hasCompleted: boolean;
}

export interface WordleMove {
  guess: string;
}

export interface WordleLetter {
  letter: string;
  status: 'correct' | 'present' | 'absent';
}

export interface WordleGuess {
  guess: string;
  letters: WordleLetter[];
}

export interface WordleResponse {
  letters: WordleLetter[];
  isCorrect: boolean;
  remainingAttempts: number;
}

export interface GuessWhoMove {
  guessedUserId: string;
}

export interface GuessWhoResult {
  guessedUserId: string;
  correct: boolean;
} 