import React, { useState, useEffect } from 'react';
import { Game, GameStatus, WordleGuess, WordleLetter } from '../../../interface/games';
import * as gamesApi from '../../../api/games';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';

interface WordleGameProps {
  game: Game;
  onUpdate: () => void;
}

const LETTERS = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
  ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
  ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'BACKSPACE']
];

const WordleGame: React.FC<WordleGameProps> = ({ game, onUpdate }) => {
  const [guess, setGuess] = useState('');
  const [error, setError] = useState<string | null>(null);
  const currentUser = authStore.currentUser;

  const myParticipation = game.participations.find(p => currentUser?.id === p.user.id);
  const guesses = myParticipation?.participationData?.guesses || [];
  const maxAttempts = game.gameData.maxAttempts || 6;
  const remainingAttempts = maxAttempts - guesses.length;
  const isGameOver = game.status === GameStatus.CLOSED || remainingAttempts <= 0 || myParticipation?.hasCompleted;

  // Get all letters that have been guessed and their statuses
  const letterStatuses = new Map<string, 'correct' | 'present' | 'absent'>();
  guesses.forEach((wordleGuess: WordleGuess) => {
    wordleGuess.letters.forEach((letter: WordleLetter) => {
      // Only update if the new status is better than the existing one
      if (!letterStatuses.has(letter.letter) || 
          (letterStatuses.get(letter.letter) === 'absent' && letter.status !== 'absent') ||
          (letterStatuses.get(letter.letter) === 'present' && letter.status === 'correct')) {
        letterStatuses.set(letter.letter, letter.status);
      }
    });
  });

  const handleKeyPress = (key: string) => {
    if (isGameOver) return;
    
    if (key === 'BACKSPACE') {
      setGuess(prev => prev.slice(0, -1));
      setError(null);
    } else if (key === 'ENTER') {
      handleSubmit();
    } else if (guess.length < 5) {
      setGuess(prev => prev + key);
      setError(null);
    }
  };

  const handleSubmit = async () => {
    if (guess.length !== 5) {
      setError('Word must be 5 letters long');
      return;
    }

    try {
      await gamesApi.submitMove(game.id, { guess });
      setGuess('');
      setError(null);
      onUpdate();
    } catch (err) {
      setError('Failed to submit guess');
      console.error(err);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isGameOver) return;
      
      if (e.key === 'Backspace') {
        handleKeyPress('BACKSPACE');
      } else if (e.key === 'Enter') {
        handleKeyPress('ENTER');
      } else if (/^[A-Za-z]$/.test(e.key)) {
        handleKeyPress(e.key.toUpperCase());
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [guess, isGameOver]);

  const renderGuess = (wordleGuess: WordleGuess) => (
    <div className="flex gap-2 mb-2">
      {wordleGuess.letters.map((letterResult: WordleLetter, index: number) => (
        <div
          key={index}
          className={`w-14 h-14 flex items-center justify-center font-bold text-2xl text-white rounded border-2 transition-all duration-500 ${
            letterResult.status === 'correct'
              ? 'bg-green-500 border-green-600'
              : letterResult.status === 'present'
              ? 'bg-yellow-500 border-yellow-600'
              : 'bg-gray-500 border-gray-600'
          }`}
        >
          {letterResult.letter}
        </div>
      ))}
    </div>
  );

  const renderEmptyRow = (rowIndex: number) => (
    <div className="flex gap-2 mb-2">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className={`w-14 h-14 flex items-center justify-center font-bold text-2xl text-gray-700 rounded border-2 border-gray-300 ${
            rowIndex === guesses.length && index < guess.length
              ? 'border-gray-400 bg-gray-50'
              : ''
          }`}
        >
          {rowIndex === guesses.length && index < guess.length ? guess[index] : ''}
        </div>
      ))}
    </div>
  );

  const renderKeyboard = () => (
    <div className="mt-8">
      {LETTERS.map((row, rowIndex) => (
        <div key={rowIndex} className="flex justify-center gap-1 mb-1">
          {row.map((letter) => {
            const status = letterStatuses.get(letter);
            return (
              <button
                key={letter}
                onClick={() => handleKeyPress(letter)}
                className={`px-2 py-4 rounded font-semibold transition-colors ${
                  letter === 'ENTER' || letter === 'BACKSPACE'
                    ? 'px-4 text-sm'
                    : 'w-10'
                } ${
                  status === 'correct'
                    ? 'bg-green-500 text-white'
                    : status === 'present'
                    ? 'bg-yellow-500 text-white'
                    : status === 'absent'
                    ? 'bg-gray-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
                }`}
              >
                {letter === 'BACKSPACE' ? '⌫' : letter}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-lg mx-auto p-4">
      <div className="mb-8 flex flex-col items-center">
        {guesses.map((guess: WordleGuess, index: number) => (
          <div key={index}>{renderGuess(guess)}</div>
        ))}
        {[...Array(maxAttempts - guesses.length)].map((_, index) => (
          <div key={`empty-${index}`}>{renderEmptyRow(guesses.length + index)}</div>
        ))}
      </div>

      {error && (
        <div className="text-red-500 text-center font-semibold mb-4 animate-bounce">
          {error}
        </div>
      )}

      {!isGameOver && game.status === GameStatus.ACTIVE && renderKeyboard()}

      {isGameOver && (
        <div className="text-center p-6 bg-gray-50 rounded-lg shadow-sm">
          <h3 className="text-2xl font-bold mb-2">Game Over!</h3>
          <p className="text-gray-600 text-lg">
            {myParticipation?.hasCompleted && myParticipation?.score > 0
              ? 'Congratulations! You found the word! 🎉'
              : 'Better luck next time! 🎯'}
          </p>
          {myParticipation?.score && myParticipation?.score > 0 && (
            <p className="text-green-600 font-semibold mt-2">
              Score: {myParticipation?.score}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(WordleGame); 